import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { TextInput } from "../../components/TextInput";
import { gql, useMutation, useQuery } from "@apollo/client";
import { USERS } from "./Users";
import { toast } from "react-toastify";
import { Permissions } from "../Permissions/Permissions";
import { GetUserQuery } from "../../gql/graphql";

const GET = gql`
  query GetUser($id: UUID!) {
    users(where: { id: { eq: $id } }) {
      items {
        id
        email
        firstName
        lastName
        mobile
        createdUtc
        isActive
      }
    }
  }
`;

const UPDATE = gql`
  mutation UpdateUser($user: UserInput!) {
    updateUser(user: $user) {
      id
      email
      firstName
      lastName
      mobile
      createdUtc
      isActive
    }
  }
`;

type UserType = NonNullable<
  NonNullable<GetUserQuery["users"]>["items"]
>[number];

export const User = () => {
  let { id, tab } = useParams();
  let navigate = useNavigate();

  const [user, setUser] = useState<UserType>(null);
  const { data } = useQuery<GetUserQuery>(GET, {
    variables: {
      id: id === "new" ? "00000000-0000-0000-0000-000000000000" : id,
    },
  });

  const [updateUser, { loading: updating }] = useMutation(UPDATE, {
    // refetchQueries: [{ query: USERS }],
    // awaitRefetchQueries: true,
  }); // [ updateCompany, { data, loading, error }]

  useEffect(() => {
    if (id === "new") {
      setUser({
        id: "00000000-0000-0000-0000-000000000000",
        email: "",
        firstName: "",
        lastName: "",
        mobile: "",
        createdUtc: null,
        isActive: true,
      });
    }
    if (data?.users?.items?.length === 1) {
      setUser(data.users.items[0]);
    }
  }, [id, data]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    if (user) {
      updateUser({
        variables: { user: { id: user.id, ...data, isActive: user.isActive } },
      }).then((x) => {
        if (x.data.updateUser) {
          toast(`${user.email} saved`, {
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
          });
          navigate("/users");
        }
      });
    }
  };

  return (
    <>
      {user && (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
            <h1 className="h2">
              {user.id === "00000000-0000-0000-0000-000000000000" ? (
                <>Add New</>
              ) : (
                <>
                  {user.email} ({user.firstName + " " + user.lastName})
                </>
              )}
            </h1>
          </div>

          <ul className="nav nav-tabs mb-3">
            <li className="nav-item">
              <Link
                className={`nav-link ${!tab ? "active" : ""}`}
                to={`/users/${user.id}`}
              >
                Details
              </Link>
            </li>

            {user.id !== "00000000-0000-0000-0000-000000000000" && (
              <>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      tab === "permissions" ? "active" : ""
                    }`}
                    to={`/users/${user.id}/permissions`}
                  >
                    Permissions
                  </Link>
                </li>
              </>
            )}
          </ul>

          {!tab && (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={
                "needs-validation" +
                (Object.keys(errors).length > 0 ? " was-validated" : "")
              }
              noValidate
            >
              <div className="row">
                <div className="col-md">
                  <TextInput
                    disabled={!!user.createdUtc}
                    name="email"
                    label="Email"
                    defaultValue={user.email ?? ""}
                    placeholder="Email"
                    register={register}
                    config={{
                      required:
                        user.id === "00000000-0000-0000-0000-000000000000",
                    }}
                    errors={errors}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md">
                  <TextInput
                    name="firstName"
                    label="First Name"
                    defaultValue={user.firstName ?? ""}
                    placeholder="First Name"
                    register={register}
                    required={false}
                    errors={errors}
                  />
                </div>
                <div className="col-md">
                  <TextInput
                    name="lastName"
                    label="Last Name"
                    defaultValue={user.lastName ?? ""}
                    placeholder="Last Name"
                    register={register}
                    required={false}
                    errors={errors}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md">
                  <TextInput
                    name="mobile"
                    label="Mobile"
                    defaultValue={user.mobile ?? ""}
                    placeholder="Mobile"
                    register={register}
                    required={false}
                    errors={errors}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md">
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="btn-check-outlined"
                    autoComplete="off"
                    onClick={() =>
                      setUser({ ...user, isActive: !user.isActive })
                    }
                  />
                  <label
                    className={
                      user.isActive ? "btn btn-success" : "btn btn-danger"
                    }
                    htmlFor="btn-check-outlined"
                  >
                    {user.isActive ? "Active" : "Inactive"}
                  </label>
                </div>
              </div>

              <div className="mt-3 mb-3">
                <input
                  disabled={updating}
                  className="btn btn-primary"
                  type="submit"
                  value="Save"
                />{" "}
                <Link to={"/users"} className="btn btn-link">
                  Cancel
                </Link>
              </div>
            </form>
          )}

          {tab === "permissions" && <Permissions userId={user.id} />}
        </>
      )}
    </>
  );
};
