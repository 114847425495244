import React from "react";
import { useParams, Link } from "react-router-dom";
//import { useAuth } from '../../auth/authContext';
import { AbnormalEvents } from "./AbnormalEvents";
import { Payments } from "./Payments";

export const Reports = () => {
  const { report } = useParams();
  //  const { roles } = useAuth();

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Reports</h1>
      </div>

      {!report && false && (
        <>
          <div className="alert alert-info">Verbose selector</div>

          <div className="row">
            <div className="col-md-4">
              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="card-title">Abnormal Events</h5>
                  <p className="card-text">
                    This report shows the latest abnormal events.
                  </p>
                  <Link
                    to="/reports/abnormal-events"
                    className="btn btn-primary"
                  >
                    View Report
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="card-title">Payments</h5>
                  <p className="card-text">
                    This report shows the most recent payments.
                  </p>
                  <Link to="/reports/payments" className="btn btn-primary">
                    View Report
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="alert alert-info">Sucinct selector</div>
        </>
      )}

      <ul className="nav nav-pills">
        <li className="nav-item">
          <Link
            to="/reports/abnormal-events"
            className={
              "nav-link" + (report === "abnormal-events" ? " active" : "")
            }
          >
            Abnormal Events
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/reports/payments"
            className={"nav-link" + (report === "payments" ? " active" : "")}
          >
            Payments
          </Link>
        </li>
      </ul>

      {report === "abnormal-events" && <AbnormalEvents />}

      {report === "payments" && <Payments />}
    </>
  );
};
