import React, { FC, useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { GetSiteQuery, GetTariffsQuery } from "../../gql/graphql";

const TARIFFS = gql`
  query GetTariffs($siteId: UUID!) {
    tariffs(siteId: $siteId) {
      items {
        id
        areaId
        dayOfWeek
        hour
        cost
      }
    }
  }
`;

const UPDATE = gql`
  mutation UpdateTariffs($tariffs: [TariffInput]!) {
    updateTariffs(tariffs: $tariffs) {
      id
      areaId
      dayOfWeek
      hour
      cost
    }
  }
`;

const dayOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

type SiteType = NonNullable<
  NonNullable<GetSiteQuery["sites"]>["items"]
>[number];
type TariffType = NonNullable<GetTariffsQuery["tariffs"]>["items"];

export const Tariffs: FC<{ site: SiteType }> = ({ site }) => {
  const [area, setArea] = useState<string | null>(null);
  const { data, refetch } = useQuery<GetTariffsQuery>(TARIFFS, {
    variables: { siteId: site?.id },
  }); // , fetchPolicy: 'no-cache'
  const [updateTariffs] = useMutation(UPDATE);
  const [tariffs, setTariffs] = useState<TariffType>([]);
  const [price, setPrice] = useState(1.0);
  const [max, setMax] = useState(0);

  useEffect(() => {
    if (area && data) {
      setTariffs(data?.tariffs?.items?.filter((t) => t?.areaId === area));
    }
  }, [area, data]);

  useEffect(() => {
    // if only one area, might as well select it by default
    if (site?.areas?.length === 1 && site?.areas[0]) {
      setArea(site?.areas[0].id);
    }
  }, [site]);

  useEffect(() => {
    if (tariffs) {
      setMax(
        tariffs?.reduce(
          (maximum, t) => (t && t.cost > maximum ? t.cost : maximum),
          0
        )
      );
    }
  }, [tariffs]);

  const cell = (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    id: string | undefined
  ) => {
    if (e.buttons === 1) {
      var ts = structuredClone(tariffs);
      const t = ts?.find((t) => t?.id === id);
      if (t) {
        t.cost = price;
      }
      setTariffs(ts);
    }
  };

  const bgColor = (cost: number | undefined) => {
    if (!cost) return "";
    var a = cost / max;
    return `rgba(0, 255, 0, ${a})`;
  };

  const saveTariffs = () => {
    updateTariffs({ variables: { tariffs: tariffs } }).then((x) => {
      if (x.data.updateTariffs) {
        toast(`tariffs updated`, { type: toast.TYPE.SUCCESS, autoClose: 3000 });

        refetch();

        setArea(null);
        setTariffs([]);
      }
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-2">Areas</div>
        <div className="col-md-10">
          {site &&
            site?.areas &&
            site.areas.map((a, index) => (
              <span
                key={index}
                className={`btn ${
                  area === a?.id ? "btn-secondary" : "btn-outline-secondary"
                } me-3`}
                onClick={() => setArea(a?.id)}
              >
                {a?.name}
              </span>
            ))}
        </div>
      </div>

      {tariffs && tariffs.length > 0 && (
        <>
          <div className="row g-3 align-items-center mt-5">
            <div className="col-auto">
              <label htmlFor="inputPrice" className="col-form-label">
                Price/Hour
              </label>
            </div>
            <div className="col-auto">
              <input
                type="number"
                step="0.1"
                id="inputPrice"
                className="form-control"
                aria-describedby="priceHelpInline"
                value={price}
                onChange={(e) =>
                  setPrice(Math.round(e.target.valueAsNumber * 100) / 100)
                }
              />
            </div>
            <div className="col-auto">
              <span id="priceHelpInline" className="form-text">
                Enter price per hour in local currency. e.g. 4.50 for $4.50
              </span>
            </div>
          </div>

          <table className="table user-select-none">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th colSpan={24} className="text-center">
                  Hour
                </th>
              </tr>
              <tr>
                <th>Day of Week</th>
                {Array.from({ length: 24 }, (v, i) => i).map((h, index) => (
                  <th style={{ textAlign: "center" }} key={"hour-" + index}>
                    {h}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: 7 }, (v, i) => i).map((dow, index) => (
                <tr key={dow + "-" + index}>
                  <td>{dayOfWeek[dow]}</td>

                  {Array.from({ length: 24 }, (u, i) => i).map((h, index) => {
                    const t = tariffs.find(
                      (t) => t?.dayOfWeek === dow && t.hour === h
                    );

                    return (
                      <td
                        className="hour-cell"
                        style={{ backgroundColor: bgColor(t?.cost) }}
                        onMouseDown={(e) => cell(e, t?.id)}
                        onMouseOver={(e) => cell(e, t?.id)}
                        key={"hour-" + index}
                      >
                        {t?.cost && t?.cost > 0 ? t?.cost : "-"}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>

          <div className="mt-3 mb-3">
            <button className="btn btn-primary" onClick={() => saveTariffs()}>
              Save
            </button>{" "}
            <button
              className="btn btn-link"
              onClick={() => {
                setArea(null);
                setTariffs([]);
              }}
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </>
  );
};
