import React, { FC, useState } from "react";
import { useAuth } from "../auth/authContext";

export const Home: FC = () => {
  const { emailVerified, sendEmailVerification } = useAuth();
  const [emailSent, setEmailSent] = useState(false);

  const sendEmail = () => {
    sendEmailVerification();
    setEmailSent(true);
  };

  return (
    <div>
      {emailVerified ? (
        <>
          <Permissions />
        </>
      ) : (
        <>
          {emailSent ? (
            <div className="alert alert-info mt-3">
              <div className="mt-3">
                <p>
                  Email sent. Check your inbox and click on the link to verify
                  your account.
                </p>
              </div>
            </div>
          ) : (
            <div className="alert alert-info mt-3">
              <p>
                Just one more step. We still need to verify your email address.
              </p>
              <div className="mt-3 text-right">
                <button className="btn btn-primary" onClick={sendEmail}>
                  Send verification email
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const Permissions = () => {
  const { roles } = useAuth();

  return (
    <>
      {roles.length > 0 ? (
        <>
          <h4>You have the following roles:</h4>
          <ul>
            {roles.map((r, i) => (
              <li key={i}>{r}</li>
            ))}
          </ul>
        </>
      ) : (
        <>
          <h4>You've not yet been set up with any roles.</h4>
          <div>Please contact your representative and ask to be set up.</div>
        </>
      )}
    </>
  );
};
