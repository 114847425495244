import React, { FC } from "react";
import { uiConfig } from "./firebase";
import { Navigate } from "react-router-dom";
import { useAuth, firebaseAuth } from "./authContext";

import StyledFirebaseAuth from "./StyledFirebaseAuth";

export const Login: FC = () => {
  const { token } = useAuth();

  return (
    <div>
      {token ? (
        <Navigate replace to="/" />
      ) : (
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseAuth} />
      )}
    </div>
  );
};
