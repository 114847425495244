import React from "react";
import { useQuery, gql } from "@apollo/client";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import ShareParkTable, {
  usePaginationAndFilters,
} from "../../components/table/ShareParkTable";
import { GetMessagesQuery, GetMessageQuery } from "../../gql/graphql";
import { ShareparkDateTime } from "../../components/LocalTime";
import { Link } from "react-router-dom";

const GET_MESSAGES = gql`
  query GetMessages($take: Int, $skip: Int) {
    messages(take: $take, skip: $skip, order: { createdUtc: DESC }) {
      items {
        id
        parkerId
        userId
        recipient
        messageType
        sentTime
        isSent
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

type Message = NonNullable<
  NonNullable<NonNullable<GetMessageQuery["messages"]>["items"]>[number]
>;
const columnHelper = createColumnHelper<Message>();

const columns = [
  columnHelper.accessor("sentTime", {
    header: "Sent",
    cell: (info) => {
      return (
        <Link to={`/messages/${info.row.original?.id}`}>
          <ShareparkDateTime timestamp={info.row.original?.sentTime} />
        </Link>
      );
    },
  }),
  columnHelper.accessor("recipient", {
    header: "Email",
  }),
];

export const Messages = () => {
  const { pagination, setPagination, columnFilters, setColumnFilters } =
    usePaginationAndFilters();

  const { loading, data } = useQuery<GetMessagesQuery>(GET_MESSAGES, {
    variables: {
      take: pagination.pageSize,
      skip: pagination.pageSize * pagination.pageIndex,
    },
    fetchPolicy: "network-only",
  });

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Messages</h1>
      </div>

      <ShareParkTable
        data={data?.messages ?? { items: [], totalCount: 0 }}
        columns={columns as ColumnDef<Message>[]}
        pagination={pagination}
        setPagination={setPagination}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        loading={loading}
      />
    </>
  );
};
