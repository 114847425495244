import React, { useEffect, useState } from "react";
import { useQuery, useSubscription, gql } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import Moment from "moment";
import "moment-timezone";
import {
  GetSitesAndStatusesQuery,
  Maybe,
  Site,
  Status as StatusType,
} from "../../gql/graphql";

export const SITES = gql`
  query GetSitesAndStatuses {
    sites {
      items {
        id
        name
        lgaName
        connectionId
        isActive
        company {
          id
          name
        }
      }
    }
    statuses {
      items {
        siteId
        key
        name
        lastUpdated
        caption
        metadata
      }
    }
  }
`;

const UPDATES = gql`
  subscription SiteStatusUpdated {
    siteUpdated {
      id
      name
      lgaName
      connectionId
      isActive
    }
  }
`;

const STATUS = gql`
  subscription StatusUpdated {
    statusUpdated {
      siteId
      key
      name
      lastUpdated
      caption
      metadata
    }
  }
`;

// const entities = [
//   "BayController",
//   "LPRCamera",
//   "MICBoard",
//   "SignServer",
//   "SystemLinkServer",
//   "VMSigns",
// ];

type NamedEntity = { name?: Maybe<string>; id?: Maybe<string> };
function compare(a: NamedEntity | null, b: NamedEntity | null) {
  if (!a || !a.name) return -1;
  if (!b || !b.name) return 1;

  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;

  if (!a.id) return -1;
  if (!b.id) return 1;

  if (a.id < b.id) return -1;
  if (a.id > b.id) return 1;

  return 0;
}

type SiteList = NonNullable<
  NonNullable<GetSitesAndStatusesQuery["sites"]>["items"]
>;
type StatusList = NonNullable<
  NonNullable<GetSitesAndStatusesQuery["statuses"]>["items"]
>;
export const Status = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  const [statuses, setStatuses] = useState<StatusList>([]); // global list of statusesType (including updates)
  const [sites, setSites] = useState<SiteList>([]); // list of sites (including updates)

  const { data, loading } = useQuery<GetSitesAndStatusesQuery>(SITES);
  const { data: subData } = useSubscription(UPDATES);
  const { data: statusData } = useSubscription(STATUS);

  const [siteStatuses, setSiteStatuses] = useState<StatusList>([]); // the status for the current site

  useEffect(() => {
    if (id && data) {
      setSiteStatuses(
        statuses.filter((status) => status?.siteId === id).sort(compare)
      );
    }
  }, [id, statuses, data]);

  useEffect(() => {
    if (subData && subData.siteUpdated) {
      const existing = sites.find(
        (site) => site?.id === subData.siteUpdated.id
      );

      if (existing) {
        existing.connectionId = subData.siteUpdated.connectionId;
        existing.isActive = subData.siteUpdated.isActive;
        existing.name = subData.siteUpdated.name;

        setSites([...sites]);
      } else {
        setSites([...sites, subData.siteUpdated]);
      }
    }
  }, [subData, sites]);

  useEffect(() => {
    if (statusData && statusData.statusUpdated) {
      var existing = statuses.find(
        (x) => x?.key === statusData.statusUpdated.key
      );

      if (existing) {
        existing.lastUpdated = statusData.statusUpdated.lastUpdated;
        existing.caption = statusData.statusUpdated.caption;
        existing.metadata = statusData.statusUpdated.metadata;

        setStatuses([...statuses]);
      } else {
        setStatuses([...statuses, statusData.statusUpdated]);
      }
    }
  }, [statusData, statuses]);

  useEffect(() => {
    if (data?.statuses?.items && data?.sites?.items) {
      setStatuses(structuredClone(data.statuses.items)); // this uglyness makes them writable
      setSites(structuredClone(data.sites.items)); // this uglyness makes them writable
    }
  }, [data]);

  return (
    <>
      <Loading loading={loading} />

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Status</h1>
      </div>

      {sites && (
        <>
          <div className="row">
            <div className="col-md-4 col-xl-2">
              <table className="table">
                <tbody>
                  {sites
                    .filter((s) => s?.isActive)
                    .sort(compare)
                    .map((site, index) => (
                      <tr
                        key={"site-" + index}
                        className={
                          site?.id === id
                            ? "table-dark text-light"
                            : site?.connectionId
                            ? "table-success"
                            : "table-danger"
                        }
                        onClick={() => navigate(`/status/${site?.id}`)}
                        style={{ cursor: "pointer" }}
                      >
                        <td>{site?.name}</td>

                        {/* <div className={`card mb-3 ${site.id === id ? 'bg-dark text-light' : ''}`} onClick={() => navigate(`/status/${site.id}`)} style={{ cursor: 'pointer' }}>
                                        <div className="card-body">
                                            <div className="float-end">
                                                { site.connectionId ? <div className="badge bg-success">Connected</div> : <div className="badge bg-danger">Not Connected</div> }
                                            </div>

                                            <h5 className={`card-title`}>{site.name}</h5>
                                        </div>
                                    </div> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="col-xl-10">
              {id && sites.length > 0 && (
                <div className="pb-3 mb-3">
                  <div>
                    <h4>
                      {sites.find((s) => s?.id === id)?.name} -{" "}
                      {sites.find((s) => s?.id === id)?.connectionId ? (
                        <div className="badge bg-success">Connected</div>
                      ) : (
                        <div className="badge bg-danger">Not Connected</div>
                      )}
                    </h4>
                  </div>

                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Caption</th>
                        <th>Address</th>
                        <th>Communication</th>
                        <th>Last Updated</th>
                        <th>No Comms Stats</th>
                      </tr>
                    </thead>
                    <tbody>
                      {siteStatuses.map((status, index) => {
                        if (!status?.metadata) return null;
                        var m = JSON.parse(status.metadata);

                        // console.log(new Date(status.lastUpdated).getTime() < new Date().getTime() - 20000);
                        // console.log(new Date().getTime());

                        return (
                          <tr key={"sitestatus-" + index}>
                            <td>{m.id}</td>
                            <td>{status.name}</td>
                            <td>{status.caption}</td>
                            <td>{m.address}</td>
                            <td
                              style={{
                                backgroundColor: m.communicationStatusColor,
                              }}
                            >
                              {m.communicationStatus}
                            </td>
                            <td>
                              {Moment.utc(status.lastUpdated.substring(0, 19))
                                .tz(Moment.tz.guess())
                                .format("DD MMM YYYY HH:mm:ss")}
                            </td>
                            <td style={{ fontFamily: "monospace" }}>
                              {m.noCommunicationStatus}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>

          {/* <div className="row">
                   
                { data.sites.filter(s => s.isActive).sort(compare).map((site, index) => 
                        <div key={'site-' + index}>
                            <div className={`card mb-3 ${site.id === id ? 'bg-dark text-light' : ''}`} onClick={() => navigate(`/status/${site.id}`)} style={{ cursor: 'pointer' }}>
                                <div className="card-body">
                                    <div className="float-end">
                                        { site.connectionId ? <div className="badge bg-success">Connected</div> : <div className="badge bg-danger">Not Connected</div> }
                                    </div>

                                    <h5 className={`card-title`}>{site.name}</h5>
                                </div>
                            </div>
                        </div>
                    )}

                </div> */}
        </>
      )}
    </>
  );
};
