import React, { FC } from "react";

type StripeMetadataItem = {
  Id: string;
  Card: {
    Brand: string;
    Last4: string;
    ExpMonth: number;
    ExpYear: number;
  };
};

export const Payment: FC<{
  parker: { stripeCustomerId?: string | null; stripeMetadata?: string | null };
}> = ({ parker }) => {
  const { stripeCustomerId, stripeMetadata } = parker;
  const metadata = stripeMetadata
    ? (JSON.parse(stripeMetadata) as StripeMetadataItem[])
    : [];

  return (
    <>
      {!stripeCustomerId && (
        <div className="alert alert-warning">
          This parker does not have a payment method setup.
        </div>
      )}

      {stripeCustomerId && (
        <>
          <div className="row">
            <div className="col-md">
              <label className="form-label">Stripe Customer Id</label>
              <input
                type="text"
                defaultValue={stripeCustomerId}
                className="form-control"
                disabled
              />
            </div>
          </div>

          <table className="table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Card</th>
                <th>Last 4</th>
                <th>Exp</th>
              </tr>
            </thead>
            <tbody>
              {metadata.map((item, index) => (
                <tr key={index}>
                  <td>{item.Id}</td>
                  <td>{item.Card.Brand}</td>
                  <td>{item.Card.Last4}</td>
                  <td>
                    {item.Card.ExpMonth + ` ` + item.Card.ExpYear}
                    {index === 0 ? (
                      <span className="badge bg-secondary ms-3">Default</span>
                    ) : (
                      ``
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};
