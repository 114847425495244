import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import "moment-timezone";
import { ShareparkDateTime } from "../../components/LocalTime";
import {
  GetReservationsQuery,
  ReservationFilterInput,
} from "../../gql/graphql";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import ShareParkTable, {
  usePaginationAndFilters,
} from "../../components/table/ShareParkTable";

export const RESERVATIONS = gql`
  query GetReservations(
    $where: ReservationFilterInput
    $take: Int
    $skip: Int
  ) {
    reservations(where: $where, take: $take, skip: $skip) {
      totalCount
      items {
        id
        createdUtc
        name
        siteId
        site {
          name
        }
        areaId
        email
        mobile
        plate
        startUtc
        startDate
        startTime
        endUtc
        endDate
        endTime
        isActive
        isPermanentParker
        source
        parker {
          id
          firstName
          lastName
          email
          mobile
        }
        vehicle {
          id
          plate
        }
      }
    }
  }
`;

type Reservation = NonNullable<
  NonNullable<
    NonNullable<GetReservationsQuery["reservations"]>["items"]
  >[number]
>;
const columnHelper = createColumnHelper<Reservation>();

const columns = [
  columnHelper.accessor("source", {
    header: "Type",
    meta: {
      filterType: "select",
      filterOptions: ["VISITOR", "PUBLIC"],
    },
    cell: (info) => {
      return (
        <>
          {info.row.original?.source === "admin"
            ? "VISITOR"
            : info.row.original?.isPermanentParker
            ? "PERM"
            : "PUBLIC"}
        </>
      );
    },
  }),
  columnHelper.accessor("createdUtc", {
    header: "Created",
    cell: (info) => {
      return <ShareparkDateTime timestamp={info.row.original?.createdUtc} />;
    },
  }),
  columnHelper.accessor("startUtc", {
    header: "Start",
    cell: (info) => {
      return (
        <Link to={`/reservations/${info.row.original?.id}`}>
          {info.row.original?.startDate} {info.row.original?.startTime}
        </Link>
      );
    },
  }),
  columnHelper.accessor("endUtc", {
    header: "End",
    cell: (info) => {
      return `${info.row.original?.endDate} ${info.row.original?.endTime}`;
    },
  }),
  columnHelper.accessor("site.name", {
    header: "Site",
  }),
  columnHelper.accessor("name", {
    header: "Purpose",
    cell: (info) => {
      return info.row.original?.source === "public"
        ? `${info.row.original?.parker?.firstName} ${info.row.original?.parker?.lastName}`
        : info.row.original?.name;
    },
  }),
  columnHelper.accessor("email", {
    header: "Email",
    cell: (info) => {
      return info.row.original?.source === "public"
        ? info.row.original?.parker?.email
        : info.row.original?.email;
    },
  }),
  columnHelper.accessor("mobile", {
    header: "Mobile",
    cell: (info) => {
      return info.row.original?.source === "public"
        ? info.row.original?.parker?.mobile
        : info.row.original?.mobile;
    },
  }),
  columnHelper.accessor("plate", {
    header: "Plate",
    cell: (info) => {
      return info.row.original?.source === "public"
        ? info.row.original?.vehicle?.plate
        : info.row.original?.plate;
    },
  }),
];

export const Reservations = () => {
  const { pagination, setPagination, columnFilters, setColumnFilters } =
    usePaginationAndFilters();
  const [showInactive, setShowInactive] = useState(false);
  const where = React.useMemo(() => {
    const where: ReservationFilterInput = {};
    if (!showInactive) {
      where.isActive = { eq: true };
    }
    for (const filter of columnFilters) {
      switch (filter.id) {
        case "source":
          where[filter.id] =
            filter.value === "VISITOR" ? { eq: "admin" } : { neq: "admin" };
          break;
        case "endUtc":
          where[filter.id] = { lte: filter.value };
          break;
        case "startUtc":
        case "createdUtc":
          where[filter.id] = { gte: filter.value };
          break;
        case "site_name":
          if (filter.value != null) {
            where.site = { name: { contains: String(filter.value) } };
          }
          break;
        case "name":
        case "email":
        case "mobile":
        case "plate":
          if (filter.value != null) {
            where[filter.id] = { contains: String(filter.value) };
          }
          break;
      }
    }
    return where;
  }, [showInactive, columnFilters]);
  const { loading, data } = useQuery<GetReservationsQuery>(RESERVATIONS, {
    variables: {
      take: pagination.pageSize,
      skip: pagination.pageSize * pagination.pageIndex,
      where,
    },
    fetchPolicy: "network-only",
  });

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Reservations</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2">
            <span
              className={
                showInactive
                  ? "btn btn-sm btn-secondary"
                  : "btn btn-sm btn-outline-secondary"
              }
              onClick={() => setShowInactive(!showInactive)}
            >
              Inactive
            </span>
          </div>
          <Link className="btn btn-sm btn-primary ml-2" to="/reservations/new">
            Add New
          </Link>
        </div>
      </div>

      <ShareParkTable
        data={data?.reservations ?? { items: [], totalCount: 0 }}
        columns={columns as ColumnDef<Reservation>[]}
        pagination={pagination}
        setPagination={setPagination}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        loading={loading}
        isActiveKey="isActive"
      />
    </>
  );
};
