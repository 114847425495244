import React, { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { GetProductsQuery, GetSiteQuery } from "../../gql/graphql";

export const PRODUCTS = gql`
  query GetProducts($siteId: UUID!) {
    products(siteId: $siteId) {
      items {
        id
        name
        description
        productTimes {
          dayOfWeek
          hour
        }
        isActive
      }
    }
  }
`;

// const TEST_PRODUCT = gql`
//   query TestProduct {
//     testProduct {
//       price
//       status
//       error
//       products {
//         name
//       }
//     }
//   }
// `;

type SiteType = NonNullable<
  NonNullable<GetSiteQuery["sites"]>["items"]
>[number];

export const Products: FC<{ site: SiteType }> = ({ site }) => {
  // const productQuery = useQuery<{ testProduct: Product }>(TEST_PRODUCT);

  // const testProduct = async () => {
  //   await productQuery.refetch();
  //   if (!productQuery.data) return;

  //   console.log(productQuery.data.testProduct?.price);

  //   console.log(productQuery.data.testProduct?.products);

  //   var content = (
  //     <>
  //       <div>
  //         {productQuery.data.testProduct?.price} -{" "}
  //         {productQuery.data.testProduct?.status}
  //       </div>
  //     </>
  //   );

  //   toast(content, { type: toast.TYPE.SUCCESS, autoClose: 5000 });
  // };

  const { data } = useQuery<GetProductsQuery>(PRODUCTS, {
    variables: { siteId: site?.id },
    fetchPolicy: "no-cache",
  });

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Products</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          {/* <div className="btn-group me-2">
            <button
              type="button"
              className="btn btn-sm btn-outline-danger"
              onClick={() => testProduct()}
            >
              Test
            </button>
          </div> */}
          <Link
            className="btn btn-sm btn-primary ms-2"
            to={`/sites/${site?.id}/products/new`}
          >
            Add New
          </Link>
        </div>
      </div>

      {data && (
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Is Active?</th>
            </tr>
          </thead>
          <tbody>
            {data.products?.items?.map((product) => {
              return (
                <tr key={product?.id}>
                  <td>
                    <Link to={`/sites/${site?.id}/products/${product?.id}`}>
                      {product?.name}
                    </Link>
                  </td>
                  <td>{product?.description}</td>
                  <td>{product?.isActive ? "Yes" : "No"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};
