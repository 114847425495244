import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { TextInput } from "../../components/TextInput";
import { gql, useMutation, useQuery } from "@apollo/client";
import { SITES } from "./Sites";
// import { USERS } from "./Users";
import { toast } from "react-toastify";
import { Testing } from "../Testing/Testing";
import { Permanents } from "../Permanent/Permanents";
import { Tariffs } from "./Tariffs";
import { Capacities } from "./Capacities";
import { Status } from "./Status";
import { Products } from "./Products";
import { Permissions } from "./Permissions";
import { useAuth } from "../../auth/authContext";
import { apiUrl } from "../../config";
import { AddressFinder } from "../../components/AddressFinder";
import { GetSiteQuery, GetSiteQueryVariables } from "../../gql/graphql";

const GET = gql`
  query GetSite($id: UUID!) {
    sites(where: { id: { eq: $id } }) {
      items {
        id
        name
        companyId
        apiKey
        daysToStoreMessages
        isActive
        isListed
        address
        addressMetadata
        lgaName
        welcomeMessage
        gracePeriod
        reservationBuffer
        timeZone
        areas {
          id
          name
          capacity
        }
      }
    }
    companies(where: { isActive: { eq: true } }, order: { name: ASC }) {
      items {
        id
        name
        isActive #need to select values in where clause
      }
    }
  }
`;

const UPDATE = gql`
  mutation UpdateSite($site: SiteInput!) {
    updateSite(site: $site) {
      id
      companyId
      name
      apiKey
      daysToStoreMessages
      isActive
      isListed
      address
      addressMetadata
      lgaName
      welcomeMessage
      gracePeriod
      reservationBuffer
      timeZone
      company {
        id
        name
      }
    }
  }
`;
type SiteType = NonNullable<
  NonNullable<GetSiteQuery["sites"]>["items"]
>[number];

export const Site = () => {
  let { id, tab } = useParams();
  const { roles } = useAuth();

  const navigate = useNavigate();

  const [validAddress, setValidAddress] = useState(false);
  const [site, setSite] = useState<SiteType | null>(null);
  const { data: timeZones } = useQuery<{ timeZones: string[] }>(
    gql`
      query {
        timeZones
      }
    `
  );
  const { data } = useQuery<GetSiteQuery, GetSiteQueryVariables>(GET, {
    variables: {
      id: id === "new" ? "00000000-0000-0000-0000-000000000000" : id,
    },
    fetchPolicy: "no-cache",
  });

  const [updateSite, { loading: updating }] = useMutation(UPDATE, {
    refetchQueries: id === "new" ? [{ query: SITES }] : [],
    awaitRefetchQueries: true,
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (site && site.addressMetadata !== "{}") {
      setValidAddress(true);
    } else {
      setValidAddress(false);
    }
  }, [site]);

  useEffect(() => {
    if (id === "new") {
      setSite({
        id: "00000000-0000-0000-0000-000000000000",
        companyId: null,
        name: "",
        isActive: true,
        daysToStoreMessages: 14,
        gracePeriod: 15,
        reservationBuffer: 1,
        isListed: false,
      });
    }
    if (data?.sites?.items?.length === 1) {
      var metaData = data.sites.items[0]?.addressMetadata
        ? JSON.parse(data.sites.items[0].addressMetadata)
        : JSON.parse("{}");

      var addressMetadata = JSON.stringify(metaData);

      if (data.sites.items[0]) {
        setSite({
          ...data.sites.items[0],
          addressMetadata: addressMetadata,
        });
      }

      var s = { ...data.sites.items[0] };
      delete (s as any).__typename;
      reset(s);
    }
  }, [id, data, reset]);

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    if (!site) return;
    //console.log(data);
    let update = {
      id: site.id,
      ...data,
      addressMetadata: site.addressMetadata,
      daysToStoreMessages: parseInt(data.daysToStoreMessages),
      gracePeriod: parseInt(data.gracePeriod),
      reservationBuffer: parseInt(data.reservationBuffer),
      isActive: site.isActive,
      isListed: site.isListed,
      areas: [],
    };
    // console.log(update);

    updateSite({ variables: { site: update } }).then((x) => {
      if (x.data.updateSite) {
        toast(`${x.data.updateSite.name} saved`, {
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        });
        navigate("/sites");
      }
    });
  };

  return (
    <>
      {site && (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
            <h1 className="h2">
              {site.name ? <>{site.name}</> : <>Add Site</>}
            </h1>
          </div>

          <ul className="nav nav-tabs mb-3">
            <li className="nav-item">
              <Link
                className={`nav-link ${!tab ? "active" : ""}`}
                to={`/sites/${site.id}`}
              >
                Details
              </Link>
            </li>

            {site.id !== "00000000-0000-0000-0000-000000000000" && (
              <>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${tab === "status" ? "active" : ""}`}
                    to={`/sites/${site.id}/status`}
                  >
                    Status
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      tab === "permissions" ? "active" : ""
                    }`}
                    to={`/sites/${site.id}/permissions`}
                  >
                    Permissions
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className={`nav-link ${tab === "tariffs" ? "active" : ""}`}
                    to={`/sites/${site.id}/tariffs`}
                  >
                    Tariffs
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      tab === "capacities" ? "active" : ""
                    }`}
                    to={`/sites/${site.id}/capacities`}
                  >
                    Capacities
                  </Link>
                </li>

                {/* <li className="nav-item">
                        <Link className={`nav-link ${tab === 'vehicles' ? 'active' : ''}`} to={`/sites/${site.id}/vehicles`}>Vehicles</Link>
                    </li> 

                    <li className="nav-item">
                        <Link className={`nav-link ${tab === 'transactions' ? 'active' : ''}`} to={`/sites/${site.id}/transactions`}>Transactions</Link>
                    </li> */}

                <li className="nav-item">
                  <Link
                    className={`nav-link ${tab === "products" ? "active" : ""}`}
                    to={`/sites/${site.id}/products`}
                  >
                    Products
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      tab === "permanent" ? "active" : ""
                    }`}
                    to={`/sites/${site.id}/permanent`}
                  >
                    Permanent
                  </Link>
                </li>

                {roles.includes("Parkagility") && (
                  <li className="nav-item">
                    <Link
                      className={`nav-link text-danger ${
                        tab === "testing" ? "active" : ""
                      }`}
                      to={`/sites/${site.id}/testing`}
                    >
                      Testing
                    </Link>
                  </li>
                )}
              </>
            )}
          </ul>

          {!tab && (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={
                "needs-validation" +
                (Object.keys(errors).length > 0 ? " was-validated" : "")
              }
              noValidate
            >
              <div className="row">
                <div className="col-md">
                  <TextInput
                    name="name"
                    label="Name"
                    defaultValue={site.name ?? ""}
                    placeholder="Name"
                    register={register}
                    config={{ required: true }}
                    errors={errors}
                  />
                </div>
                <div className="col-md">
                  <label className="required form-label" htmlFor="companyId">
                    Company
                  </label>
                  <select
                    className="form-select"
                    defaultValue={site.companyId}
                    {...register("companyId", { required: true })}
                    required
                  >
                    <option value="">Choose a company</option>
                    {data &&
                      data.companies?.items?.map((company) => (
                        <option key={company?.id} value={company?.id}>
                          {company?.name}
                        </option>
                      ))}
                  </select>
                  <div className="invalid-feedback">
                    Please choose a company, or add one if needed.
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <AddressFinder
                    enabled={!!site}
                    onAddressSelect={(fullAddress, metaData) => {
                      setSite({
                        ...site,
                        addressMetadata: JSON.stringify(metaData),
                      });
                      setValue("address", fullAddress);
                    }}
                    onLocationSelect={(fullLocation, metaData) => {
                      setSite({
                        ...site,
                        addressMetadata: JSON.stringify(metaData),
                      });
                    }}
                    id="address"
                    name="address"
                    label="Address"
                    defaultValue={site.address ?? ""}
                    placeholder="Address"
                    register={register}
                    required={false}
                    errors={errors}
                  />
                </div>
                <div className="col-md">
                  <label className="required form-label" htmlFor="timeZone">
                    Time Zone
                  </label>
                  <select
                    className="form-control"
                    id="timeZone"
                    defaultValue={site.timeZone ?? ""}
                    {...register("timeZone", { required: true })}
                    required
                  >
                    <option value="">Choose a timezone</option>
                    {timeZones?.timeZones.map((tz) => (
                      <option key={tz} value={tz}>
                        {tz}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">
                    Please choose a timezone for this site.
                  </div>
                </div>
              </div>

              <div className="card mt-3">
                <div className="card-body">
                  <h6 className="card-title">SenseIQ</h6>

                  <div className="alert alert-info">
                    Connection URL:{" "}
                    <strong>https://{apiUrl}/shareparkHub</strong>
                  </div>

                  <div className="row">
                    <div className="col">
                      <TextInput
                        name="apiKey"
                        label="API Key"
                        defaultValue={site.apiKey ?? ""}
                        placeholder="Guid from Sharepark v1/Apikey for v2"
                        register={register}
                        errors={errors}
                      />
                    </div>
                    <div className="col">
                      <TextInput
                        name="daysToStoreMessages"
                        type="number"
                        label="Days to store messages"
                        defaultValue={site.daysToStoreMessages}
                        placeholder="# days to keep messages"
                        register={register}
                        config={{ required: true }}
                        errors={errors}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md">
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="isListed"
                    autoComplete="off"
                    onClick={() =>
                      setSite({ ...site, isListed: !site.isListed })
                    }
                  />
                  <label
                    className={
                      site.isListed && validAddress
                        ? "btn btn-success"
                        : "btn btn-danger"
                    }
                    htmlFor="isListed"
                  >
                    {site.isListed && validAddress
                      ? "Listed Publicly"
                      : "Not Listed"}
                  </label>

                  {!validAddress && (
                    <div className="text-danger">
                      If you want to publicly list this site, please enter an
                      address
                    </div>
                  )}
                </div>
                <div className="col-md">
                  <TextInput
                    name="gracePeriod"
                    type="number"
                    label="Grace Period (mins)"
                    defaultValue={site.gracePeriod}
                    placeholder="# mins of Grace"
                    register={register}
                    config={{ required: true }}
                    errors={errors}
                  />
                  <TextInput
                    name="reservationBuffer"
                    type="number"
                    label="Reservation Buffer (hours)"
                    defaultValue={site.reservationBuffer}
                    placeholder="# hours after a Reservation to block/buffer a bay"
                    register={register}
                    config={{ required: true }}
                    errors={errors}
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <TextInput
                    name="welcomeMessage"
                    label="Welcome Message"
                    defaultValue={site.welcomeMessage ?? ""}
                    placeholder="put custom welcome message here"
                    register={register}
                    errors={errors}
                  />

                  <div className="alert alert-info">
                    [firstname], [lastname] or [plate] will be replaced
                    automatically. SenseIq can also use [bay].
                  </div>
                </div>
                <div className="col"></div>
              </div>

              <div className="row mt-3">
                <div className="col-md">
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="isActive"
                    autoComplete="off"
                    onClick={() =>
                      setSite({ ...site, isActive: !site.isActive })
                    }
                  />
                  <label
                    className={
                      site.isActive ? "btn btn-success" : "btn btn-danger"
                    }
                    htmlFor="isActive"
                  >
                    {site.isActive ? "Active" : "Inactive"}
                  </label>
                </div>
              </div>

              <div className="mt-3 mb-3">
                <input
                  disabled={updating}
                  className="btn btn-primary"
                  type="submit"
                  value="Save"
                />{" "}
                <Link to={"/sites"} className="btn btn-link">
                  Cancel
                </Link>
              </div>
            </form>
          )}

          {tab === "status" && <Status site={site} />}

          {tab === "products" && <Products site={site} />}

          {tab === "permissions" && <Permissions site={site} />}

          {tab === "tariffs" && <Tariffs site={site} />}

          {tab === "capacities" && <Capacities site={site} />}

          {tab === "permanent" && <Permanents siteId={site.id} />}

          {tab === "testing" && <Testing siteId={site.id} />}
        </>
      )}
    </>
  );
};
