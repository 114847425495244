import React, { FC } from "react";

export const PermissionIcon: FC = () => {
  return (
    <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M7 14C5.9 14 5 13.1 5 12S5.9 10 7 10 9 10.9 9 12 8.1 14 7 14M12.6 10C11.8 7.7 9.6 6 7 6C3.7 6 1 8.7 1 12S3.7 18 7 18C9.6 18 11.8 16.3 12.6 14H16V18H20V14H23V10H12.6Z"
      />
    </svg>
  );
};

export const DeleteIcon: FC = () => {
  return (
    <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z"
      />
    </svg>
  );
};

export const ImpersonateIcon: FC = () => {
  return (
    <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M16 9C22 9 22 13 22 13V15H16V13C16 13 16 11.31 14.85 9.8C14.68 9.57 14.47 9.35 14.25 9.14C14.77 9.06 15.34 9 16 9M8 11C11.5 11 11.94 12.56 12 13H4C4.06 12.56 4.5 11 8 11M8 9C2 9 2 13 2 13V15H14V13C14 13 14 9 8 9M9 17V19H15V17L18 20L15 23V21H9V23L6 20L9 17M8 3C8.55 3 9 3.45 9 4S8.55 5 8 5 7 4.55 7 4 7.45 3 8 3M8 1C6.34 1 5 2.34 5 4S6.34 7 8 7 11 5.66 11 4 9.66 1 8 1M16 1C14.34 1 13 2.34 13 4S14.34 7 16 7 19 5.66 19 4 17.66 1 16 1Z"
      />
    </svg>
  );
};
