import React, { FC, useEffect, useMemo, useState } from "react";
import { Row, Col, Container, Button } from "reactstrap";
import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  sortingFns,
  getSortedRowModel,
  FilterFn,
  SortingFn,
  ColumnDef,
  flexRender,
  PaginationState,
  OnChangeFn,
} from "@tanstack/react-table";
import {
  RankingInfo,
  rankItem,
  compareItems,
} from "@tanstack/match-sorter-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { CollectionSegmentInfo, Maybe, Scalars } from "../../gql/graphql";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 300,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

interface PaginationInfo {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
}

type Paginated<T> = {
  items?: Maybe<Array<Maybe<T>>>;
  pageInfo?: CollectionSegmentInfo;
  totalCount?: Scalars["Int"];
  pagination?: PaginationInfo; // New addition for pagination data from headers
};

type BooleanKeys<T> = {
  [K in keyof T]: T[K] extends boolean ? (K extends string ? K : never) : never;
}[keyof T];

interface ReactTableProps<
  T extends Record<string, unknown> | null,
  K extends BooleanKeys<T>
> {
  data?: Paginated<T>;
  columns: ColumnDef<T>[];
  pagination: PaginationState;
  setPagination: OnChangeFn<PaginationState>;
  columnFilters: ColumnFiltersState;
  setColumnFilters: OnChangeFn<ColumnFiltersState>;
  loading?: boolean;
  isActiveKey?: K;
}

export const usePaginationAndFilters = (
  defaultColumnFilters: ColumnFiltersState | (() => ColumnFiltersState) = []
) => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const [columnFilters, setColumnFilters] =
    useState<ColumnFiltersState>(defaultColumnFilters);
  return {
    pagination,
    setPagination,
    columnFilters,
    setColumnFilters,
  };
};

function ShareParkTable<
  T extends Record<string, unknown> | null,
  K extends BooleanKeys<T>
>({
  columns,
  data,
  pagination,
  setPagination,
  columnFilters,
  setColumnFilters,
  loading,
  isActiveKey,
}: ReactTableProps<T, K>) {
  // const [globalFilter, setGlobalFilter] = React.useState("");
  // TODO: maintain table height between pages

  const pageCount = Math.max(
    1,
    data?.totalCount == null
      ? 1
      : Math.ceil(data?.totalCount / pagination.pageSize)
  );
  const table = useReactTable<T>({
    data: (data?.items?.filter((_) => _) as T[]) ?? [],
    columns,
    pageCount,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      pagination,
      columnFilters,
      // globalFilter,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    // getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  useEffect(() => {
    if (!loading && pagination.pageIndex >= pageCount) {
      setPagination({
        ...pagination,
        pageIndex: 0,
      });
    }
  }, [pagination, pageCount]);

  // const globalSearchId = `table-global-search-${useId()}`;

  // Render the UI for your table
  return (
    <div className="table-responsive">
      <Row
        style={{
          margin: "0.25em 0.25em 0.25em 0",
        }}
      >
        {/* <label className="col-sm-2 col-form-label" htmlFor={globalSearchId}>
          Search All:{" "}
        </label>
        <Col sm={10}>
          <DebouncedInput
            id={globalSearchId}
            className="form-control"
            value={globalFilter}
            onChange={(value) => {
              setGlobalFilter(value.toString());
            }}
            placeholder="Search all records..."
            style={{
              fontSize: "1.1rem",
              border: "0",
            }}
          />
        </Col> */}
        {/* <Col className="text-right">{actions}</Col> */}
      </Row>

      <table className="table table-hover">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  style={{ whiteSpace: "nowrap", userSelect: "none" }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? "cursor-pointer select-none"
                          : "",
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {{
                        asc: (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            className="fa-lg me-1"
                            style={{ color: "#20a8d8" }}
                          />
                        ),
                        desc: (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="fa-lg me-1"
                            style={{ color: "#20a8d8" }}
                          />
                        ),
                      }[header.column.getIsSorted() as string] ?? (
                        <FontAwesomeIcon
                          icon={faSort}
                          className="fa-lg me-1"
                          style={{ color: "#ccc" }}
                        />
                      )}
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  style={{ whiteSpace: "nowrap", userSelect: "none" }}
                >
                  {header.isPlaceholder ||
                  !header.column.getCanFilter() ? null : (
                    <div>
                      <Filter column={header.column} table={table} />
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className={
                !isActiveKey || row?.original?.[isActiveKey] === true
                  ? ""
                  : "table-secondary"
              }
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
      <Container>
        <Row className="justify-content-between align-items-center">
          <Col xs="auto">
            <Row className="align-items-center">
              <Col xs="auto">
                <Button
                  className="border rounded p-1 px-2"
                  onClick={() => table.setPageIndex(0)}
                  disabled={!table.getCanPreviousPage()}
                >
                  {"<<"}
                </Button>
                <Button
                  className="border rounded p-1 px-2"
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  {"<"}
                </Button>
                <Button
                  className="border rounded p-1 px-2"
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                >
                  {">"}
                </Button>
                <Button
                  className="border rounded p-1 px-2"
                  onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                  disabled={!table.getCanNextPage()}
                >
                  {">>"}
                </Button>
                {loading && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="fa-spin fa-lg ms-2"
                  />
                )}
              </Col>
              <Col xs="auto">
                <span>
                  Page
                  <strong>
                    {pagination.pageIndex + 1} of{" "}
                    {Math.max(1, table.getPageCount())}
                  </strong>
                </span>
              </Col>
            </Row>
          </Col>
          <Col xs="auto">
            <Row className="align-items-center">
              <Col xs="auto">
                Go to page:{" "}
                <input
                  type="number"
                  defaultValue={pagination.pageIndex + 1}
                  style={{ width: 85 }}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    table.setPageIndex(page);
                  }}
                  className="border p-1 rounded w-16"
                />
              </Col>
              <Col xs="auto">
                <select
                  className="form-select"
                  value={pagination.pageSize}
                  onChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                  }}
                >
                  {[2, 10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function Filter({
  column,
}: {
  column: Column<any, unknown>;
  table: Table<any>;
}) {
  const { meta } = column.columnDef;
  const filterType = (meta as { filterType: string })?.filterType ?? "text";
  switch (filterType) {
    case "range":
      return <NumberRangeColumnFilter column={column} />;
    case "select":
      return <SelectColumnFilter column={column} />;
    default:
      return <DefaultColumnFilter column={column} />;
  }
}

const DefaultColumnFilter: FC<{ column: Column<any, unknown> }> = ({
  column,
}) => {
  const columnFilterValue = column.getFilterValue();
  const sortedUniqueValues = React.useMemo(
    () => Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  );
  return (
    <>
      <datalist id={column.id + "list"}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? "") as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        className="form-control"
        list={column.id + "list"}
      />
    </>
  );
};

// // This is a custom filter UI for selecting
// // a unique option from a list
const SelectColumnFilter: FC<{ column: Column<any, unknown> }> = ({
  column,
}) => {
  const { filterOptions } = column.columnDef.meta as {
    filterOptions: string[];
  };
  return (
    <select
      className="form-select"
      value={String(column.getFilterValue() || "")}
      onChange={(e) => {
        column.setFilterValue(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {(filterOptions as string[]).map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};
// // This is a custom filter UI that uses a
// // slider to set the filter value between a column's
// // min and max values
// function SliderColumnFilter<
//   K extends string,
//   T extends Record<string, unknown> & Record<K, number>
// >({
//   column: { filterValue, setFilter, preFilteredRows, id },
// }: ColumnFilterProps<T, K, number>) {
//   // Calculate the min and max
//   // using the preFilteredRows

//   const [min, max] = React.useMemo(() => {
//     let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
//     let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
//     preFilteredRows.forEach((row) => {
//       min = Math.min(row.values[id], min);
//       max = Math.max(row.values[id], max);
//     });
//     return [min, max];
//   }, [id, preFilteredRows]);

//   return (
//     <>
//       <input
//         type="range"
//         min={min}
//         max={max}
//         value={filterValue || min}
//         onChange={(e) => {
//           setFilter(e.target.valueAsNumber);
//         }}
//       />
//       <button onClick={() => setFilter(undefined)}>Off</button>
//     </>
//   );
// }

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
const NumberRangeColumnFilter: FC<{
  column: Column<any, unknown>;
}> = ({ column }) => {
  const columnFilterValue = column.getFilterValue();
  const currMin = (columnFilterValue as [number, number])?.[0];
  const currMax = (columnFilterValue as [number, number])?.[1];

  return (
    <div style={{ display: "flex" }}>
      <DebouncedInput
        type="number"
        value={currMin ?? ""}
        onChange={(value) => {
          const val =
            value === "" || value == null
              ? null
              : typeof value === "string"
              ? parseFloat(value)
              : value;

          column.setFilterValue((old: [number, number]) => [val, old?.[1]]);
        }}
        title={`Min`}
        placeholder={`Min`}
        className="form-control"
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      <DebouncedInput
        type="number"
        value={currMax ?? ""}
        onChange={(value) => {
          const val =
            value === "" || value == null
              ? null
              : typeof value === "string"
              ? parseFloat(value)
              : value;
          column.setFilterValue((old: [number, number]) => [old?.[0], val]);
        }}
        title={`Max`}
        placeholder={`Max`}
        className="form-control"
        style={{
          width: "70px",
        }}
      />
    </div>
  );
};

export default ShareParkTable;
