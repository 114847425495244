import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { gql, useMutation, useQuery } from "@apollo/client";
import { PERMISSIONS } from "../Permissions/Permissions";
import { toast } from "react-toastify";
import { GetPermissionQuery } from "../../gql/graphql";

const GET = gql`
  query GetPermission($permissionId: UUID!, $userId: UUID!) {
    users(where: { id: { eq: $userId } }) {
      items {
        id
        email
        firstName
        lastName
      }
    }
    permissions(where: { id: { eq: $permissionId } }) {
      items {
        id
        userId
        siteId
        companyId
        areaId
        role
      }
    }
    companies(where: { isActive: { eq: true } }) {
      items {
        id
        name
        isActive #need to select values in where clause
      }
    }
    sites(where: { isActive: { eq: true } }) {
      items {
        id
        name
        isActive #need to select values in where clause
      }
    }
    areas {
      items {
        id
        name
        siteId
        site {
          id
          name
        }
      }
    }
  }
`;

const roles = ["Read", "Write", "Admin"];

const UPDATE = gql`
  mutation UpdatePermission($permission: PermissionInput!) {
    updatePermission(permission: $permission) {
      id
      userId
      siteId
      companyId
      areaId
      role
    }
  }
`;

type UserType = NonNullable<
  NonNullable<GetPermissionQuery["users"]>["items"]
>[number];
type PermissionType = NonNullable<
  NonNullable<GetPermissionQuery["permissions"]>["items"]
>[number];

export const Permission = () => {
  const { id: userId, permissionId } = useParams();
  const navigate = useNavigate();

  const [user, setUser] = useState<UserType | null>(null);
  const [permission, setPermission] = useState<PermissionType | null>(null);
  const [company, setCompany] = useState<string | null>(null);
  const [site, setSite] = useState<string | null>(null);
  const [area, setArea] = useState<string | null>(null);
  const [role, setRole] = useState<string | null>(null);

  const { data } = useQuery<GetPermissionQuery>(GET, {
    variables: {
      permissionId:
        permissionId === "new"
          ? "00000000-0000-0000-0000-000000000000"
          : permissionId,
      userId: userId,
    },
  });

  const [updatePermission, { loading: updating }] = useMutation(UPDATE, {
    refetchQueries: [{ query: PERMISSIONS, variables: { userId: userId } }],
    awaitRefetchQueries: true,
  }); // [ updateCompany, { data, loading, error }]

  useEffect(() => {
    if (permissionId === "new") {
      setPermission({
        id: "00000000-0000-0000-0000-000000000000",
        companyId: null,
        siteId: null,
        areaId: null,
        role: null,
      });
    }
    if (data?.permissions?.items?.length === 1) {
      const permission = data.permissions.items[0];
      setPermission(permission);
      setCompany(permission?.companyId);
      setSite(permission?.siteId);
      setArea(permission?.areaId);
      setRole(permission?.role ?? null);
    }
    if (data?.users?.items?.length === 1) {
      setUser(data.users?.items[0]);
    }
  }, [data, permissionId]);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (company != null) {
      setSite(null);
      setArea(null);
    }
  }, [company]);

  useEffect(() => {
    if (area != null) {
      setSite(null);
      setCompany(null);
    }
  }, [area]);

  useEffect(() => {
    if (site != null) {
      setCompany(null);
      setArea(null);
    }
  }, [site]);

  useEffect(() => {
    if (role === "Parkagility") {
      setCompany(null);
      setSite(null);
      setArea(null);
    }
  }, [role]);

  const onSubmit = () => {
    var permsToSet = {
      id: permission?.id,
      userId: user?.id,
      companyId: company,
      siteId: site,
      areaId: area,
      role: role,
    };

    updatePermission({ variables: { permission: permsToSet } }).then((x) => {
      if (x.data.updatePermission) {
        toast(`${user?.email} permission saved`, {
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        });
        navigate(`/users/${user?.id}/permissions`);
      }
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">
          Permissions - {user?.email} ({user?.firstName + " " + user?.lastName})
        </h1>
      </div>

      <div className="text-info mb-5">
        To add a permission for a user, choose either a Company, Site or Area,
        then the role you'd like to grant.
      </div>

      {permission && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={
            "needs-validation" +
            (Object.keys(errors).length > 0 ? " was-validated" : "")
          }
          noValidate
        >
          <div className="row mb-3">
            <div className="col-sm-2">Company</div>
            <div className="col-sm-10">
              {data?.companies?.items?.map((c) => (
                <span
                  key={c?.id}
                  className={`btn ${
                    company === c?.id
                      ? "btn-secondary"
                      : "btn-outline-secondary"
                  } me-3 mb-3`}
                  onClick={() => setCompany(c?.id)}
                >
                  {c?.name}
                </span>
              ))}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-sm-2">Sites</div>
            <div className="col-sm-10">
              {data?.sites?.items?.map((s) => (
                <span
                  key={s?.id}
                  className={`btn ${
                    site === s?.id ? "btn-secondary" : "btn-outline-secondary"
                  } me-3`}
                  onClick={() => setSite(s?.id)}
                >
                  {s?.name}
                </span>
              ))}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-sm-2">Areas</div>
            <div className="col-sm-10">
              {data?.areas?.items?.map((a) => (
                <span
                  key={a?.id}
                  className={`btn ${
                    area === a?.id ? "btn-secondary" : "btn-outline-secondary"
                  } me-3`}
                  onClick={() => setArea(a?.id)}
                >
                  {a?.site?.name} : {a?.name}
                </span>
              ))}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-sm-2">Role</div>
            <div className="col-sm-10">
              {roles.map((r, index) => (
                <span
                  key={index}
                  className={`btn ${
                    role === r ? "btn-secondary" : "btn-outline-secondary"
                  } me-3`}
                  onClick={() => setRole(r)}
                >
                  {r}
                </span>
              ))}

              <span
                className={`btn ${
                  role === "Parkagility" ? "btn-danger" : "btn-outline-danger"
                } mr-3`}
                onClick={() => setRole("Parkagility")}
              >
                Parkagility
              </span>
            </div>
          </div>
          <input
            disabled={updating}
            className="btn btn-primary"
            type="submit"
            value="Save"
          />{" "}
          <Link to={`/users/${userId}/permissions`} className="btn btn-link">
            Cancel
          </Link>
        </form>
      )}
    </>
  );
};
