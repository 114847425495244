import React, { FC, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useQuery, useMutation, gql } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DeleteIcon } from "../../components/Icons";
import { GetPermissionsQuery } from "../../gql/graphql";

export const PERMISSIONS = gql`
  query GetPermissions($userId: UUID!) {
    permissions(where: { userId: { eq: $userId } }) {
      items {
        id
        userId # need to select values in where clause
        company {
          id
          name
        }
        site {
          id
          name
        }
        area {
          id
          name
          site {
            id
            name
          }
        }
        role
      }
    }
    users(where: { id: { eq: $userId } }) {
      items {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

export const DELETE = gql`
  mutation DeletePermissions($id: UUID!) {
    deletePermission(id: $id)
  }
`;

type PermissionType = NonNullable<
  NonNullable<GetPermissionsQuery["permissions"]>["items"]
>[number];

export const Permissions: FC<{ userId?: string }> = ({ userId }) => {
  let { id } = useParams();
  if (!userId) {
    userId = id;
  }

  const [del, setDel] = useState<PermissionType | null>(null);

  const { data } = useQuery<GetPermissionsQuery>(PERMISSIONS, {
    variables: { userId },
  });

  const deletePerm = (perm: PermissionType) => {
    setDel(perm);
  };

  return (
    <div>
      {del && <DeleteModal permission={del} setDel={setDel} />}

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">{!userId && <span>Permissions</span>}</h1>

        <div className="btn-toolbar mb-2 mb-md-0">
          <Link
            className="btn btn-sm btn-primary"
            to={"/users/" + userId + "/permissions/new"}
          >
            Add New
          </Link>
        </div>
      </div>

      {data && data.permissions && (
        <table className="table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Name</th>
              <th>Role</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {data.permissions?.items?.map((permission) => (
              <tr key={permission?.id}>
                {permission?.site && (
                  <>
                    <td>
                      <Link to={`/users/${id}/permissions/${permission?.id}`}>
                        SITE
                      </Link>
                    </td>
                    <td>{permission?.site.name}</td>
                  </>
                )}
                {permission?.company && (
                  <>
                    <td>
                      <Link to={`/users/${id}/permissions/${permission?.id}`}>
                        COMPANY
                      </Link>
                    </td>
                    <td>{permission?.company.name}</td>
                  </>
                )}
                {permission?.area && (
                  <>
                    <td>
                      <Link to={`/users/${id}/permissions/${permission?.id}`}>
                        AREA
                      </Link>
                    </td>
                    <td>
                      {permission?.area?.site?.name} : {permission?.area.name}
                    </td>
                  </>
                )}

                {permission?.site === null &&
                  permission?.company === null &&
                  permission?.area === null && (
                    <>
                      <td>
                        <Link to={`/users/${id}/permissions/${permission?.id}`}>
                          SYSTEM
                        </Link>
                      </td>
                      <td>&nbsp;</td>
                    </>
                  )}

                <td>{permission?.role}</td>
                <td>
                  <span onClick={() => deletePerm(permission)}>
                    <DeleteIcon />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

const DeleteModal: FC<{
  permission: PermissionType;
  setDel: (id: PermissionType | null) => void;
}> = ({ permission, setDel }) => {
  let { id } = useParams();

  const [deletePermission] = useMutation(DELETE, {
    refetchQueries: [{ query: PERMISSIONS, variables: { userId: id } }],
    awaitRefetchQueries: true,
  }); //

  const confirm = () => {
    deletePermission({ variables: { id: permission?.id } }).then((x) => {
      if (x.data.deletePermission) {
        toast(`permission deleted`, {
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        });
        setDel(null);
      }
    });
  };

  return (
    <Modal isOpen={permission !== null}>
      <ModalHeader>Are you sure?</ModalHeader>
      <ModalBody>
        <p>This will delete that permission</p>
        <p>
          {permission?.site && <>SITE: {permission?.site.name}</>}
          {permission?.company && <>COMPANY: {permission?.company.name}</>}
          {permission?.area && <>AREA: {permission?.area.name}</>}
        </p>
        <p>ROLE: {permission?.role}</p>
      </ModalBody>
      <ModalFooter>
        {" "}
        <span className="btn btn-link" onClick={() => setDel(null)}>
          Cancel
        </span>{" "}
        <Button color="primary" onClick={() => confirm()}>
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};
