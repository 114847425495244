import React, { FC, useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import { GetVehiclesQuery, VehicleFilterInput } from "../../gql/graphql";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import ShareParkTable, {
  usePaginationAndFilters,
} from "../../components/table/ShareParkTable";

export const VEHICLES = gql`
  query GetVehicles($filter: VehicleFilterInput, $take: Int, $skip: Int) {
    vehicles(where: $filter, take: $take, skip: $skip) {
      totalCount
      items {
        id
        parkerId
        parker {
          id
          email
          firstName
          lastName
        }
        plate
        make
        model
        colour
        state
        isActive
        isElectric
      }
    }
  }
`;

type Vehicle = NonNullable<
  NonNullable<NonNullable<GetVehiclesQuery["vehicles"]>["items"]>[number]
>;
const columnHelper = createColumnHelper<Vehicle>();

const columns = [
  columnHelper.accessor("plate", {
    header: "Plate",
    cell: (info) => {
      return (
        <Link to={`/vehicles/${info.row.original?.id}`}>
          {info.renderValue() ? info.renderValue() : "(No Plate)"}
        </Link>
      );
    },
  }),
  columnHelper.accessor("parker.email", {
    header: "Email",
    cell: (info) => {
      return (
        <Link to={"/parkers/" + info.row.original.parkerId}>
          {info.renderValue()}
        </Link>
      );
    },
  }),
  columnHelper.accessor("make", {
    header: "Make",
  }),
  columnHelper.accessor("model", {
    header: "Model",
  }),
  columnHelper.accessor("colour", {
    header: "Colour",
  }),
  columnHelper.accessor("state", {
    header: "State",
  }),
];

const VehicleHeaders = [
  { label: "ID", key: "id" },
  { label: "Email", key: "email" },
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Plate", key: "plate" },
  { label: "Make", key: "make" },
  { label: "Model", key: "model" },
  { label: "Colour", key: "colour" },
  { label: "State", key: "state" },
  { label: "Is Active", key: "isActive" },
];

type VehicleType = NonNullable<
  NonNullable<NonNullable<GetVehiclesQuery["vehicles"]>["items"]>[number]
>;

export const Vehicles: FC<{ parkerId?: string }> = ({ parkerId }) => {
  const { parkerId: id } = useParams();
  if (!parkerId) parkerId = id;

  const { pagination, setPagination, columnFilters, setColumnFilters } =
    usePaginationAndFilters();
  const [showInactive, setShowInactive] = useState(false);

  const where = React.useMemo(() => {
    const where: VehicleFilterInput = {};

    if (parkerId) {
      where.parkerId = { eq: parkerId };
    }

    if (!showInactive) {
      where.isActive = { eq: true };
    }
    for (const filter of columnFilters) {
      switch (filter.id) {
        case "parker_email":
          if (filter.value != null) {
            where.parker = { email: { contains: String(filter.value) } };
          }
          break;
        case "plate":
        case "make":
        case "model":
        case "colour":
        case "state":
          if (filter.value != null) {
            where[filter.id] = { contains: String(filter.value) };
          }
          break;
      }
    }
    return where;
  }, [showInactive, columnFilters]);
  const { data, loading } = useQuery<GetVehiclesQuery>(VEHICLES, {
    variables: {
      take: pagination.pageSize,
      skip: pagination.pageSize * pagination.pageIndex,
      filter: where,
    },
    fetchPolicy: "network-only",
  });
  const [flat, setFlat] = useState<
    (Omit<VehicleType, "parker"> &
      Pick<NonNullable<VehicleType["parker"]>, "firstName" | "lastName">)[]
  >([]);

  useEffect(() => {
    if (data && data.vehicles) {
      let flat = data?.vehicles?.items
        ?.filter((x) => x?.isActive || showInactive)
        .map((vehicle) => {
          return {
            id: vehicle?.id,
            email: vehicle?.parker?.email,
            firstName: vehicle?.parker?.firstName,
            lastName: vehicle?.parker?.lastName,
            plate: vehicle?.plate,
            make: vehicle?.make,
            model: vehicle?.model,
            colour: vehicle?.colour,
            state: vehicle?.state,
            isActive: vehicle?.isActive ?? false,
            isElectric: vehicle?.isElectric ?? false,
          };
        });
      if (flat) {
        setFlat(flat);
      }
    }
  }, [data, showInactive]);

  const parkerUrl = parkerId ? `/parkers/${parkerId}` : "";

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">{!parkerId && <span>Vehicles</span>}</h1>

        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2">
            {!parkerId && (
              <CSVLink
                className="btn btn-sm btn-outline-secondary"
                data={flat as object[]}
                headers={VehicleHeaders}
                filename={"vehicles.csv"}
              >
                Export
              </CSVLink>
            )}
            {
              <span
                className={
                  showInactive
                    ? "btn btn-sm btn-secondary"
                    : "btn btn-sm btn-outline-secondary"
                }
                onClick={() => setShowInactive(!showInactive)}
              >
                Inactive
              </span>
            }
          </div>

          <Link
            className="btn btn-sm btn-primary ml-2"
            to={parkerUrl + "/vehicles/new"}
          >
            Add New
          </Link>
        </div>
      </div>

      <ShareParkTable
        data={data?.vehicles ?? { items: [], totalCount: 0 }}
        columns={columns as ColumnDef<Vehicle>[]}
        pagination={pagination}
        setPagination={setPagination}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        loading={loading}
        isActiveKey="isActive"
      />
    </div>
  );
};
