import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import Loading from "../../components/Loading";
import { GetParkersQuery, ParkerFilterInput } from "../../gql/graphql";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import ShareParkTable, {
  usePaginationAndFilters,
} from "../../components/table/ShareParkTable";

export const PARKERS = gql`
  query GetParkers($where: ParkerFilterInput, $take: Int, $skip: Int) {
    parkers(where: $where, take: $take, skip: $skip) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        email
        firstName
        lastName
        mobile
        createdUtc
        isActive
        stripeCustomerId
        isEmailVerified
        isProfileComplete
      }
    }
  }
`;

type Parker = NonNullable<
  NonNullable<NonNullable<GetParkersQuery["parkers"]>["items"]>[number]
>;

const columnHelper = createColumnHelper<Parker>();

const columns = [
  columnHelper.accessor("email", {
    header: "Email",
    cell: (info) => {
      return (
        <Link to={"/parkers/" + info.row.original.id}>
          {info.renderValue()}
        </Link>
      );
    },
  }),
  columnHelper.accessor("firstName", {
    header: "First Name",
  }),
  columnHelper.accessor("lastName", {
    header: "Last Name",
  }),
  columnHelper.accessor("mobile", {
    header: "Mobile",
  }),
  columnHelper.accessor("stripeCustomerId", {
    header: "Payment Details",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("isEmailVerified", {
    header: "Email Verified",
    enableColumnFilter: false,
    cell: (info) => {
      return info.renderValue() ? "Y" : null;
    },
  }),
  columnHelper.accessor("isProfileComplete", {
    header: "Profile Complete",
    enableColumnFilter: false,
    cell: (info) => {
      return info.renderValue() ? "Y" : null;
    },
  }),
];

const PARKER_HEADERS = [
  { label: "ID", key: "id" },
  { label: "Email", key: "email" },
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Mobile", key: "mobile" },
  { label: "Created", key: "createdUtc" },
  { label: "Active", key: "isActive" },
];

export const Parkers = () => {
  const { pagination, setPagination, columnFilters, setColumnFilters } =
    usePaginationAndFilters();
  const [showInactive, setShowInactive] = useState(false);
  const where = React.useMemo(() => {
    const where: ParkerFilterInput = {};
    if (!showInactive) {
      where.isActive = { eq: true };
    }
    for (const filter of columnFilters) {
      switch (filter.id) {
        case "isEmailVerified":
        case "isProfileComplete":
          if (filter.value != null) {
            where[filter.id] = { eq: !!filter.value };
          }
          break;
        case "stripeCustomerId":
        case "email":
        case "firstName":
        case "lastName":
        case "mobile":
          if (filter.value != null) {
            where[filter.id] = { contains: String(filter.value) };
          }
          break;
      }
    }
    return where;
  }, [showInactive, columnFilters]);
  const { loading, data } = useQuery<GetParkersQuery>(PARKERS, {
    variables: {
      take: pagination.pageSize,
      skip: pagination.pageSize * pagination.pageIndex,
      where,
    },
    fetchPolicy: "network-only",
  });

  return (
    <div>
      <Loading loading={loading} />

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Parkers</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2">
            {data?.parkers?.items && (
              <CSVLink
                filename={"parkers.csv"}
                className="btn btn-sm btn-outline-secondary"
                headers={PARKER_HEADERS}
                data={data.parkers.items as object[]}
              >
                Export
              </CSVLink>
            )}
            <span
              className={
                showInactive
                  ? "btn btn-sm btn-secondary"
                  : "btn btn-sm btn-outline-secondary"
              }
              onClick={() => setShowInactive(!showInactive)}
            >
              Inactive
            </span>
          </div>
          <Link className="btn btn-sm btn-primary ms-2" to="/parkers/new">
            Add New
          </Link>
        </div>
      </div>

      <ShareParkTable
        data={data?.parkers ?? { items: [], totalCount: 0 }}
        columns={columns as ColumnDef<Parker>[]}
        pagination={pagination}
        setPagination={setPagination}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        loading={loading}
        isActiveKey="isActive"
      />
    </div>
  );
};
