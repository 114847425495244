import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";
import Moment from "moment";
import "moment-timezone";
import { toast } from "react-toastify";
import { GetTransactionQuery } from "../../gql/graphql";

const GET = gql`
  query GetTransaction($transactionId: UUID!) {
    transactionById(id: $transactionId) {
      id
      startUtc
      endUtc
      reservation {
        id
        startDate
        startTime
        endDate
        endTime
        name
        email
        mobile
        plate
        parkerId
        vehicleId
      }
      parker {
        id
        firstName
        lastName
      }
      vehicle {
        id
        plate
      }
      siteMessages {
        id
        type
        createdUtc
      }
      events {
        id
        type
        message
        createdUtc
      }
      invoices {
        id
        createdUtc
        amount
        status
        stripeInvoiceId
        stripeInvoiceNumber
        stripeInvoicePdfUrl
        invoiceItems {
          id
          amount
          description
          quantity
          unitPrice
          stripeId
        }
      }
    }
  }
`;

// const CLEARSITEDATA = gql`
// mutation ClearSiteData($siteId: UUID!) {
//     clearSiteData(siteId: $siteId){
//         id
//     }
// }
// `;

const RESET_TRANSACTION = gql`
  mutation ResetTransaction($transactionId: UUID!) {
    resetTransaction(transactionId: $transactionId) {
      id
    }
  }
`;

export const Transaction = () => {
  let { transactionId } = useParams();

  const { data, refetch } = useQuery<GetTransactionQuery>(GET, {
    variables: {
      transactionId,
    },
    fetchPolicy: "no-cache",
  });
  const [resetTransaction] = useMutation(RESET_TRANSACTION, {
    variables: { transactionId },
  });

  const transaction = data?.transactionById;

  const resetThisTransaction = async () => {
    const x = await resetTransaction({ variables: { transactionId } });
    if (x.data.resetTransaction) {
      toast(`Transaction payment is reset`, {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });

      await refetch();
    }
  };

  return (
    <>
      {transaction && (
        <div className="ms-md-2">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
            <h1 className="h2">Transaction</h1>
          </div>

          <div className="mb-3 alert alert-danger row">
            <div className="col">
              <div>Redo Invoicing for this transaction</div>
              <div>
                This removes the existing invoice and line items, then
                recalculates and reinvoices.
              </div>
            </div>
            <div className="col">
              <button
                className="btn btn-danger float-end"
                onClick={() => resetThisTransaction()}
              >
                Reset Transaction
              </button>
            </div>
          </div>

          {transaction?.reservation && (
            <div className="row p-3 border rounded bg-light mb-3">
              <div className="col-md-2 text-md-end">Reservation:</div>
              <div className="col-md-10 fw-bold">
                <Link to={"/reservations/" + transaction.reservation.id}>
                  {transaction.reservation.startDate}{" "}
                  {transaction.reservation.startTime} to{" "}
                  {transaction.reservation.endDate}{" "}
                  {transaction.reservation.endTime}
                </Link>
              </div>
            </div>
          )}

          {transaction.parker && (
            <div className="row p-3 border rounded bg-light mb-3">
              <div className="col-md-2 text-md-end">Parker:</div>
              <div className="col-md-10 fw-bold">
                <Link to={"/parkers/" + transaction.parker.id}>
                  {transaction.parker.firstName} {transaction.parker.lastName}
                </Link>
              </div>
            </div>
          )}

          {transaction.vehicle && (
            <div className="row p-3 border rounded bg-light mb-3">
              <div className="col-md-2 text-md-end">Vehicle:</div>
              <div className="col-md-10 fw-bold">
                <Link to={"/vehicles/" + transaction.vehicle.id}>
                  {transaction.vehicle.plate}
                </Link>
              </div>
            </div>
          )}

          {transaction.invoices && transaction.invoices.length > 0 && (
            <div className="row p-3 border rounded bg-light mb-3">
              <div className="col-md-2 text-md-end">Invoices:</div>
              <div className="col-md-10">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Date</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                      <th scope="col">PDF</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transaction.invoices.map((invoice, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{invoice?.stripeInvoiceNumber}</th>
                          <td>
                            {Moment.utc(invoice?.createdUtc?.substring(0, 19))
                              .tz(Moment.tz.guess())
                              .format("DD MMM YYYY HH:mm:ss")}
                          </td>
                          <td>${invoice?.amount.toFixed(2)}</td>
                          <td>{invoice?.status}</td>
                          <td>
                            {invoice?.stripeInvoicePdfUrl && (
                              <a href={invoice?.stripeInvoicePdfUrl}>
                                DOWNLOAD {invoice?.stripeInvoiceNumber}
                              </a>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {transaction.events && transaction.events.length > 0 && (
            <>
              <div className="row p-3 border rounded bg-light mb-3">
                <div className="col-md-2 text-md-end">Events:</div>
                <div className="col-md-10">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>When</th>
                        <th>Type</th>
                        <th>Message</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transaction.events.map((event) => (
                        <tr key={event?.id}>
                          <td>
                            {Moment.utc(event?.createdUtc?.substring(0, 19))
                              .tz(Moment.tz.guess())
                              .format("DD MMM YYYY HH:mm:ss")}
                          </td>
                          <td>{event?.type}</td>
                          <td>{event?.message}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}

          {transaction.siteMessages && transaction.siteMessages.length > 0 && (
            <>
              <div className="row p-3 border rounded bg-light mb-3">
                <div className="col-md-2 text-md-end">SiteMessages:</div>
                <div className="col-md-10">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>When</th>
                        <th>Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transaction.siteMessages.map((siteMessage) => (
                        <tr key={siteMessage?.id}>
                          <td>
                            {Moment.utc(
                              siteMessage?.createdUtc?.substring(0, 19)
                            )
                              .tz(Moment.tz.guess())
                              .format("DD MMM YYYY HH:mm:ss")}
                          </td>
                          <td>{siteMessage?.type}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
