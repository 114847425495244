import React, { ComponentProps, FC, useEffect, useRef } from "react";
import { TextInput } from "./TextInput";

declare global {
  interface Window {
    AddressFinder: {
      Widget: {
        new (element: HTMLElement, key: string, country: string, options: {}): {
          on: (
            event: string,
            callback: (fullAddress: string, metaData: {}) => void
          ) => void;
        };
      };
    };
  }
}

export const AddressFinder: FC<
  {
    enabled: boolean;
    onAddressSelect: (fullAddress: string, metaData: {}) => void;
    onLocationSelect: (fullLocation: string, metaData: {}) => void;
  } & ComponentProps<typeof TextInput>
> = ({ enabled, onAddressSelect, onLocationSelect, ...props }) => {
  const textInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (enabled && textInputRef.current) {
      const widget = new window.AddressFinder.Widget(
        textInputRef.current,
        "GR3KNJMHCYTBWPLF7Q64",
        "AU",
        {
          address_params: {
            gnaf: "1",
          },
          show_locations: true,
          location_params: {
            location_types: "street, locality, state",
          },
          byline: false,
          empty_content: "No address found.",
        }
      );

      widget.on("address:select", onAddressSelect);
      widget.on("location:select", onLocationSelect);

      return () => {
        // TODO: Determine if we need to clean anything up
      };
    }
  }, [enabled, onAddressSelect, onLocationSelect]);

  return <TextInput ref={textInputRef} {...props} />;
};
