import React from "react";
import { useQuery, gql } from "@apollo/client";
import { ShareparkDateTime } from "../../components/LocalTime";
import { GetInvoicesQuery, InvoiceFilterInput } from "../../gql/graphql";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import ShareParkTable, {
  usePaginationAndFilters,
} from "../../components/table/ShareParkTable";

export const GET = gql`
  query GetInvoices($take: Int!, $skip: Int!) {
    invoices(take: $take, skip: $skip) {
      totalCount
      items {
        id
        createdUtc
        transactionId
        amount
        status
        parker {
          id
          fullName
          firstName
          lastName
          email
        }
        transaction {
          id
          startUtc
          endUtc
          site {
            id
            name
          }
        }
        stripeInvoiceNumber
        stripeInvoicePdfUrl
      }
    }
  }
`;

type Invoice = NonNullable<
  NonNullable<NonNullable<GetInvoicesQuery["invoices"]>["items"]>[number]
>;

const currencyFormatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const columnHelper = createColumnHelper<Invoice>();

const columns = [
  columnHelper.accessor("createdUtc", {
    header: "When",
    enableColumnFilter: false,
    cell: (info) => {
      return <ShareparkDateTime timestamp={info.getValue()} />;
    },
  }),
  columnHelper.accessor("transaction.id", {
    header: "Transaction",
    cell: (info) => {
      return (
        <Link to={`/transactions/${info.getValue()}`}>{info.getValue()}</Link>
      );
    },
  }),
  columnHelper.accessor("transaction.site.name", {
    header: "Site",
    cell: (info) => {
      return (
        <Link to={`/sites/${info.row.original.transaction?.site?.id}`}>
          {info.getValue()}
        </Link>
      );
    },
  }),
  columnHelper.accessor("parker.fullName", {
    header: "Parker",
    cell: (info) => {
      return (
        <Link to={`/parkers/${info.row.original?.parker?.id}`}>
          {info.row.original?.parker?.fullName}
        </Link>
      );
    },
  }),
  columnHelper.accessor("amount", {
    header: "Amount",
    meta: {
      filterType: "range",
    },
    cell: (info) => {
      return (
        <div style={{ textAlign: "right", width: "80px" }}>
          {currencyFormatter.format(info.getValue())}
        </div>
      );
    },
  }),
  columnHelper.accessor("stripeInvoiceNumber", {
    header: "Invoice Number",
  }),
  columnHelper.accessor("status", {
    header: "Status",
  }),
  columnHelper.accessor("stripeInvoicePdfUrl", {
    header: "PDF",
    enableColumnFilter: false,
    cell: (info) => {
      const val = info.getValue();
      if (!val) return null;
      return (
        <a href={val} target="_blank" rel="noreferrer">
          PDF
        </a>
      );
    },
  }),
];

export const Payments = () => {
  const { pagination, setPagination, columnFilters, setColumnFilters } =
    usePaginationAndFilters();
  const where = React.useMemo(() => {
    const where: InvoiceFilterInput = {};
    for (const filter of columnFilters) {
      switch (filter.id) {
        case "transaction_id":
          if (filter.value != null) {
            where.transaction = where.transaction ?? {};
            where.transaction!.id = { eq: String(filter.value) };
          }
          break;
        case "transaction_site.name":
          if (filter.value != null) {
            where.transaction = where.transaction ?? {};
            where.transaction!.site = {
              name: { contains: String(filter.value) },
            };
          }
          break;
        case "parker_fullName":
          if (filter.value != null) {
            where.parker = {
              or: [
                {
                  firstName: {
                    contains: String(filter.value).split(" ").shift(),
                  },
                },
                {
                  lastName: { contains: String(filter.value).split(" ").pop() },
                },
              ],
            };
          }
          break;
        case "amount":
          if (filter.value != null) {
            const [min, max] = filter.value as [number?, number?];
            if (min == null && max == null) {
              break;
            }
            const f: InvoiceFilterInput["amount"] = {};
            if (min != null) {
              f.gte = min;
            }
            if (max != null) {
              f.lte = max;
            }
            where[filter.id] = f;
          }
          break;
        case "status":
        case "stripeInvoiceNumber":
          if (filter.value != null) {
            where[filter.id] = { contains: String(filter.value) };
          }
          break;
      }
    }
    return where;
  }, [columnFilters]);
  const { data, loading } = useQuery<GetInvoicesQuery>(GET, {
    variables: {
      take: pagination.pageSize,
      skip: pagination.pageSize * pagination.pageIndex,
      where,
    },
    fetchPolicy: "no-cache",
  });

  return (
    <div className="border-top mt-3">
      {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
            <h1 className="h2">Abnormal Events</h1>
        </div>     */}

      <ShareParkTable
        data={data?.invoices ?? { items: [], totalCount: 0 }}
        columns={columns as ColumnDef<Invoice>[]}
        pagination={pagination}
        setPagination={setPagination}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        loading={loading}
      />
    </div>
  );
};
