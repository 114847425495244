import React, { FC } from "react";
import Moment from "moment";
import "moment-timezone";

export const ShareparkDateTime: FC<{
  timestamp?: string | null;
  showAsInput?: boolean;
}> = ({ timestamp, showAsInput }) => {
  if (!timestamp) {
    return null;
  }
  //{Moment.utc(invoice.createdUtc.substring(0, 19)).tz(Moment.tz.guess()).format("DD MMM YYYY HH:mm:ss")}
  const date = Moment.utc(timestamp.substring(0, 19)).tz("Australia/Sydney"); // we could use best-guess

  return showAsInput ? (
    <>
      <input
        className="form-control"
        type="text"
        disabled={true}
        value={date.format("DD MMM YYYY HH:mm")}
      />
    </>
  ) : (
    <>
      {date.format("DD MMM YYYY")} {date.format("HH:mm")}
    </>
  );
};
