import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { TextInput } from "../../components/TextInput";
import { gql, useMutation, useQuery } from "@apollo/client";
import { PARKERS } from "./Parkers";
import { toast } from "react-toastify";
import { Vehicles } from "../Vehicles/Vehicles";
import { Transactions } from "../Transactions/Transactions";
import { QRCodeSVG } from "qrcode.react";
import { Testing } from "../Testing/Testing";
import { Permanents } from "../Permanent/Permanents";
import { Payment } from "./Payment";
import { GetParkerQuery, Parker as ParkerType } from "../../gql/graphql";

const GET = gql`
  query GetParker($id: UUID!) {
    parkers(where: { id: { eq: $id } }) {
      items {
        id
        email
        firstName
        lastName
        mobile
        createdUtc
        isActive
        isEmailVerified
        qrCode
        uid
        streetNumber
        streetName
        suburb
        state
        postcode
        notificationType
        agreeTermsConditions
        stripeCustomerId
        stripeMetadata
        isEmailVerified
        isProfileComplete
      }
    }
  }
`;

const UPDATE = gql`
  mutation UpdatePermanentParker($parker: ParkerInput!) {
    updateParker(parker: $parker) {
      id
      email
      firstName
      lastName
      mobile
      createdUtc
      isActive
      isEmailVerified
      qrCode
    }
  }
`;

const QRCODE = gql`
  mutation ReissueQrCode($parker: ParkerInput!) {
    reissueQrCode(parker: $parker) {
      id
      email
      firstName
      lastName
      mobile
      createdUtc
      isActive
      isEmailVerified
      qrCode
    }
  }
`;

export const Parker = () => {
  let { parkerId, tab } = useParams();
  const navigate = useNavigate();

  const [parker, setParker] = useState<
    NonNullable<NonNullable<GetParkerQuery["parkers"]>["items"]>[number] | null
  >(null);
  const { data } = useQuery<GetParkerQuery>(GET, {
    variables: {
      id:
        parkerId === "new" ? "00000000-0000-0000-0000-000000000000" : parkerId,
    },
  });

  const [updateParker, { loading: updating }] = useMutation<
    {
      updateParker: ParkerType;
    },
    {
      parker: ParkerType;
    }
  >(UPDATE, {
    refetchQueries: parkerId === "new" ? [{ query: PARKERS }] : [],
    awaitRefetchQueries: true,
  }); // [ updateCompany, { data, loading, error }]
  const [reissueQrCode] = useMutation<
    {
      reissueQrCode: ParkerType;
    },
    {
      parker: Pick<ParkerType, "id" | "isActive" | "isEmailVerified">;
    }
  >(QRCODE);

  useEffect(() => {
    if (parkerId === "new") {
      setParker({
        id: "00000000-0000-0000-0000-000000000000",
        email: "",
        firstName: "",
        lastName: "",
        mobile: "",
        createdUtc: null,
        isActive: true,
        isEmailVerified: false,
      });
    }
    if (data?.parkers?.items?.length === 1) {
      setParker(data.parkers.items[0]);
    }
  }, [data, parkerId]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    updateParker({
      variables: {
        parker: {
          ...(data as ParkerType),
          id: parker?.id!,
          isActive: parker?.isActive!,
          isEmailVerified: parker?.isEmailVerified!,
        },
      },
    }).then((x) => {
      if (x.data?.updateParker) {
        toast(`${parker?.email} saved`, {
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        });
        navigate("/parkers");
      }
    });
  };

  const onReissueQrCode = () => {
    reissueQrCode({
      variables: {
        parker: {
          id: parker?.id!,
          isActive: parker?.isActive!,
          isEmailVerified: parker?.isEmailVerified!,
        },
      },
    }).then((x) => {
      if (x.data?.reissueQrCode) {
        toast(`New QR Code issued`, {
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        });
      }
    });
  };

  return (
    <>
      {parker && (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2">
            <h1 className="h2">
              Parker {parker.email ? " - " + parker.email : ""}
            </h1>
          </div>

          <ul className="nav nav-tabs mb-3">
            <li className="nav-item">
              <Link
                className={`nav-link ${!tab ? "active" : ""}`}
                to={`/parkers/${parker.id}`}
              >
                Details
              </Link>
            </li>

            {parker.id !== "00000000-0000-0000-0000-000000000000" && (
              <>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${tab === "qr-code" ? "active" : ""}`}
                    to={`/parkers/${parker.id}/qr-code`}
                  >
                    QR Code
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className={`nav-link ${tab === "payment" ? "active" : ""}`}
                    to={`/parkers/${parker.id}/payment`}
                  >
                    Payment
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className={`nav-link ${tab === "vehicles" ? "active" : ""}`}
                    to={`/parkers/${parker.id}/vehicles`}
                  >
                    Vehicles
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      tab === "transactions" ? "active" : ""
                    }`}
                    to={`/parkers/${parker.id}/transactions`}
                  >
                    Transactions
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      tab === "permanent" ? "active" : ""
                    }`}
                    to={`/parkers/${parker.id}/permanent`}
                  >
                    Permanent
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className={`nav-link text-danger ${
                      tab === "testing" ? "active" : ""
                    }`}
                    to={`/parkers/${parker.id}/testing`}
                  >
                    Testing
                  </Link>
                </li>
              </>
            )}
          </ul>

          {!tab && (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={
                "needs-validation" +
                (Object.keys(errors).length > 0 ? " was-validated" : "")
              }
              noValidate
            >
              <div className="row">
                <div className="col-md">
                  <TextInput
                    disabled={!!parker.createdUtc}
                    name="email"
                    label="Email"
                    defaultValue={parker.email ?? ""}
                    placeholder="Email"
                    register={register}
                    config={{
                      required:
                        parker.id === "00000000-0000-0000-0000-000000000000",
                    }}
                    errors={errors}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md">
                  <TextInput
                    name="firstName"
                    label="First Name"
                    defaultValue={parker.firstName ?? ""}
                    placeholder="First Name"
                    register={register}
                    config={{ required: false }}
                    errors={errors}
                  />
                </div>
                <div className="col-md">
                  <TextInput
                    name="lastName"
                    label="Last Name"
                    defaultValue={parker.lastName ?? ""}
                    placeholder="Last Name"
                    register={register}
                    config={{ required: false }}
                    errors={errors}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md">
                  <TextInput
                    name="mobile"
                    label="Mobile"
                    defaultValue={parker.mobile ?? ""}
                    placeholder="Mobile"
                    register={register}
                    config={{ required: false }}
                    errors={errors}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md">
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="btn-check-outlined"
                    autoComplete="off"
                    onClick={() =>
                      setParker({ ...parker, isActive: !parker.isActive })
                    }
                  />
                  <label
                    className={
                      parker.isActive ? "btn btn-success" : "btn btn-danger"
                    }
                    htmlFor="btn-check-outlined"
                  >
                    {parker.isActive ? "Active" : "Inactive"}
                  </label>
                </div>
              </div>

              <div className="mt-3 mb-3">
                <input
                  disabled={updating}
                  className="btn btn-primary"
                  type="submit"
                  value="Save"
                />{" "}
                <Link to={"/parkers"} className="btn btn-link">
                  Cancel
                </Link>
              </div>
            </form>
          )}

          {tab === "qr-code" && (
            <>
              <div className="mb-3">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={onReissueQrCode}
                >
                  Reissue QR Code
                </button>
              </div>

              {parker?.qrCode && parker?.uid && (
                <QRCodeSVG
                  value={parker.uid}
                  size={256}
                  bgColor={"#ffffff"}
                  fgColor={"#000000"}
                  level={"L"}
                  includeMargin={false}
                />
              )}
            </>
          )}

          {tab === "payment" && parker.uid && <Payment parker={parker} />}
          {tab === "testing" && parker.uid && <Testing qrCode={parker.uid} />}
          {tab === "vehicles" && <Vehicles parkerId={parker.id} />}
          {tab === "transactions" && <Transactions parkerId={parker.id} />}
          {tab === "permanent" && <Permanents parkerId={parker.id} />}
        </>
      )}
    </>
  );
};
