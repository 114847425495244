import React, { FC, useState } from "react";
import { Container } from "reactstrap";
import NavMenu, { SideBar } from "./NavMenu";
import "./Layout.css";
import { useAuth } from "../auth/authContext";

export const Layout: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  const { currentUser } = useAuth();
  const isAuthenticated =
    currentUser != null &&
    (currentUser.emailVerified === true ||
      currentUser.providerData[0].providerId !== "password");

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <NavMenu collapsed={collapsed} setCollapsed={toggleCollapsed} />

      {isAuthenticated ? (
        <div className="container-fluid">
          <div className="row">
            <SideBar collapsed={collapsed} setCollapsed={toggleCollapsed} />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-3">
              {children}
            </main>
          </div>
        </div>
      ) : (
        <Container fluid={true}>
          <div style={{ marginTop: "120px" }}>{children}</div>
        </Container>
      )}
    </>
  );
};
