import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
  from,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { WebSocketLink } from "@apollo/client/link/ws";

import { onError } from "@apollo/client/link/error";
import { toast } from "react-toastify";
import { getStoredProfile, useAuth } from "./auth/authContext";
import { apiUrl } from "./config";
import React, { FC, useEffect } from "react";

let connectingIssue = false;
let token: string | null | undefined;

const getToken = () => {
  const user = getStoredProfile("impersonate") || getStoredProfile("user");
  return user?.token;
};

const authLink = new ApolloLink((operation, forward) => {
  token = token || getToken();
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  }));

  if (operation.variables) {
    const omitTypename = (key: string, value: unknown) =>
      key === "__typename" ? undefined : value;
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }

  return forward(operation);
});

const wsLink = new WebSocketLink({
  uri: "wss://" + apiUrl + "/graphql",
  options: {
    // reconnect: true,
    // connectionParams: {
    //   authToken: getToken(),
    // },
    connectionParams: () => {
      return {
        authToken: getToken(),
      };
    },
    connectionCallback: (error: any) => {
      if (error?.message === "Your connection was rejected.") {
        //token likely expired/old. Get new one
        if (connectingIssue) return;

        console.error(error);

        if (!!token) {
          console.error(error?.message);
          connectingIssue = true;
        }
      }
    },
  },
});

const errorLink = onError(
  ({ graphQLErrors, networkError, response, operation, forward }) => {
    console.log({ graphQLErrors, networkError, response, operation });

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        toast(`${message}`, { type: toast.TYPE.ERROR, autoClose: 3000 });
      });
    }

    if (networkError) {
      toast(`Network Error: ${networkError}`, {
        type: toast.TYPE.WARNING,
        autoClose: 3000,
      });
    }

    if (response) response.errors = undefined;

    forward(operation);
  }
);

export const ApolloClientProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { setApolloClient: setAuthApolloClient } = useAuth();
  const [apolloClient, setApolloClientState] = React.useState<
    ApolloClient<NormalizedCacheObject> | undefined
  >(undefined);

  useEffect(() => {
    if (!apolloClient) {
      const apolloCli = new ApolloClient({
        cache: new InMemoryCache(),
        link: from([new RetryLink(), errorLink, authLink, wsLink]),
      });
      setApolloClientState(apolloCli);
      setAuthApolloClient(apolloCli);
    }
  }, [apolloClient, setAuthApolloClient]);

  if (apolloClient) {
    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
  }

  return <></>;
};
