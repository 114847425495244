import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useQuery, useSubscription, gql } from "@apollo/client";
import ReactJson from "react-json-view";
import Loading from "../../components/Loading";
import { ShareparkDateTime } from "../../components/LocalTime";
import {
  GetSiteCommandsQuery,
  SiteCommandFilterInput,
} from "../../gql/graphql";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import ShareParkTable, {
  usePaginationAndFilters,
} from "../../components/table/ShareParkTable";

const GET = gql`
  query GetSiteCommands(
    $where: SiteCommandFilterInput
    $take: Int
    $skip: Int
  ) {
    siteCommands(
      where: $where
      take: $take
      skip: $skip
      order: { createdUtc: DESC }
    ) {
      items {
        id
        siteId
        site {
          name
        }
        type
        createdUtc
        payload
        response
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

const UPDATES = gql`
  subscription SiteCommandReceived {
    siteCommandReceived {
      id
      siteId
      type
      createdUtc
      payload
      response
    }
  }
`;

type SiteCommand = NonNullable<
  NonNullable<GetSiteCommandsQuery["siteCommands"]>["items"]
>[number];

const columnHelper = createColumnHelper<SiteCommand>();

const columns = [
  columnHelper.accessor("site.name", {
    header: "Site",
    cell: (info) => {
      return (
        <Link to={"/sites/" + info.row.original?.siteId}>
          {info.renderValue()}
        </Link>
      );
    },
  }),
  columnHelper.accessor("createdUtc", {
    header: "Timestamp",
    enableColumnFilter: false,
    cell: (info) => {
      return <ShareparkDateTime timestamp={info.row.original?.createdUtc} />;
    },
  }),
  columnHelper.accessor("type", {
    header: "Event Type",
  }),
  columnHelper.accessor("payload", {
    header: "Event Data",
    enableColumnFilter: false,
    cell: (info) => {
      if (!info.row.original?.payload) return null;
      return (
        <ReactJson
          name={null}
          src={JSON.parse(info.row.original?.payload)}
          collapsed={1}
          style={{ fontSize: "0.75rem" }}
        />
      );
    },
  }),
  columnHelper.accessor("response", {
    header: "Message Result",
    enableColumnFilter: false,
  }),
];

export const SiteCommands = () => {
  const { pagination, setPagination, columnFilters, setColumnFilters } =
    usePaginationAndFilters();
  const [messages, setMessages] = useState<SiteCommand[]>([]);

  const latestMessages = useRef(messages);
  latestMessages.current = messages;

  const where = React.useMemo(() => {
    const where: SiteCommandFilterInput = {};
    for (const filter of columnFilters) {
      switch (filter.id) {
        case "createdUtc":
          where[filter.id] = { gte: filter.value };
          break;
        case "site_name":
          if (filter.value != null) {
            where.site = { name: { contains: String(filter.value) } };
          }
          break;
        case "type":
        case "payload":
        case "response":
          if (filter.value != null) {
            where[filter.id] = { contains: String(filter.value) };
          }
          break;
      }
    }
    return where;
  }, [columnFilters]);
  const { loading, data } = useQuery<GetSiteCommandsQuery>(GET, {
    fetchPolicy: "no-cache",
    variables: {
      where,
      take: pagination.pageSize,
      skip: pagination.pageSize * pagination.pageIndex,
    },
  });
  const { data: subData } = useSubscription(UPDATES);

  useEffect(() => {
    if (data && data.siteCommands) {
      const msgs = data.siteCommands?.items?.filter(Boolean) as SiteCommand[];
      setMessages(msgs);
    }
  }, [data]);

  useEffect(() => {
    if (subData) {
      var latest = [...latestMessages.current];
      latest.unshift(subData.siteCommandReceived);

      if (latest.length > 20) latest.length = 20;

      setMessages(latest);
    }
  }, [subData]);

  return (
    <div>
      <Loading loading={loading} />

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Commands</h1>
      </div>

      <ShareParkTable
        data={data?.siteCommands ?? { items: [], totalCount: 0 }}
        columns={columns as ColumnDef<SiteCommand>[]}
        pagination={pagination}
        setPagination={setPagination}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        loading={loading}
      />
    </div>
  );
};
