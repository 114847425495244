import React, { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { GetSitePermissionsQuery, GetSiteQuery } from "../../gql/graphql";

export const PERMISSIONS = gql`
  query GetSitePermissions($siteId: UUID!) {
    sitePermissions(siteId: $siteId) {
      items {
        id
        company {
          id
          name
        }
        site {
          id
          name
        }
        area {
          id
          name
        }
        user {
          id
          firstName
          lastName
          email
        }
        role
      }
    }
  }
`;

type SiteType = NonNullable<
  NonNullable<GetSiteQuery["sites"]>["items"]
>[number];

export const Permissions: FC<{ site: SiteType }> = ({ site }) => {
  const { data } = useQuery<GetSitePermissionsQuery>(PERMISSIONS, {
    variables: { siteId: site?.id },
    fetchPolicy: "no-cache",
  });

  return (
    <>
      {data && (
        <table className="table">
          <thead>
            <tr>
              <th>User</th>
              <th>Email</th>
              <th>Site/Area</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {data.sitePermissions?.items?.map((perm) => {
              return (
                <tr key={perm?.id}>
                  <td>
                    {perm?.user?.firstName} {perm?.user?.lastName}
                  </td>
                  <td>
                    <Link to={`/users/${perm?.user?.id}/permissions`}>
                      {perm?.user?.email}
                    </Link>
                  </td>
                  <td>
                    {perm?.area?.name ? "AREA: " + perm?.area.name : "SITE"}
                  </td>
                  <td>{perm?.role}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};
