import React, { useEffect, useState } from "react";
import { useQuery, useSubscription, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import { GetSitesQuery, SiteFilterInput } from "../../gql/graphql";
import ShareParkTable, {
  usePaginationAndFilters,
} from "../../components/table/ShareParkTable";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

const no = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill="#DC3545"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L16.9 18.31C15.55 19.37 13.85 20 12 20zm6.31-3.1L7.1 5.69C8.45 4.63 10.15 4 12 4c4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z" />
  </svg>
);
const yes = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill="#198754"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M18.92 5.01C18.72 4.42 18.16 4 17.5 4h-11c-.66 0-1.21.42-1.42 1.01L3 11v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.5 15c-.83 0-1.5-.67-1.5-1.5S5.67 12 6.5 12s1.5.67 1.5 1.5S7.33 15 6.5 15zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5 10l1.5-4.5h11L19 10H5z" />
  </svg>
);

export const SITES = gql`
  query GetSites($where: SiteFilterInput, $take: Int, $skip: Int) {
    sites(where: $where, take: $take, skip: $skip) {
      totalCount
      items {
        id
        name
        lgaName
        isListed
        connectionId
        isActive
        company {
          id
          name
        }
      }
    }
  }
`;

// const UPDATES = gql`
//   subscription SiteUpdated {
//     siteUpdated {
//       id
//       name
//       lgaName
//       connectionId
//       isActive
//       isListed
//     }
//   }
// `;

type Site = NonNullable<
  NonNullable<NonNullable<GetSitesQuery["sites"]>["items"]>[number]
>;

const columnHelper = createColumnHelper<Site>();

const columns = [
  columnHelper.accessor("name", {
    header: "Name",
    cell: (info) => {
      return (
        <Link to={"/sites/" + info.row.original.id}>{info.renderValue()}</Link>
      );
    },
  }),
  columnHelper.accessor("company.name", {
    header: "Company",
    cell: (info) => {
      return (
        <Link to={"/companies/" + info.row.original.company?.id}>
          {info.renderValue()}
        </Link>
      );
    },
  }),
  columnHelper.accessor("lgaName", {
    header: "Region",
  }),
  columnHelper.accessor("isListed", {
    header: "Is Listed",
  }),
  columnHelper.accessor("connectionId", {
    header: "SenseIQ",
  }),
];

export const Sites = () => {
  const { pagination, setPagination, columnFilters, setColumnFilters } =
    usePaginationAndFilters();
  //const { roles } = useAuth();
  const [showInactive, setShowInactive] = useState(false);
  const where = React.useMemo(() => {
    const where: SiteFilterInput = {};
    if (!showInactive) {
      where.isActive = { eq: true };
    }
    for (const filter of columnFilters) {
      switch (filter.id) {
        case "companyName":
          if (filter.value != null) {
            where.company = { name: { contains: String(filter.value) } };
          }
          break;
        case "name":
        case "lgaName":
        case "connectionId":
        case "isListed":
          if (filter.value != null) {
            where[filter.id] = { contains: String(filter.value) };
          }
          break;
      }
    }
    return where;
  }, [columnFilters, showInactive]);

  const { data, loading } = useQuery<GetSitesQuery>(SITES, {
    variables: {
      take: pagination.pageSize,
      skip: pagination.pageSize * pagination.pageIndex,
      where,
    },
    fetchPolicy: "network-only",
  });
  //const { data: subData } = useSubscription(UPDATES);
  //const subscription = useSubscription(UPDATES);

  //useEffect(() => {}, [subData]);

  return (
    <>
      <Loading loading={loading} />

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Sites</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2">
            {data?.sites?.items?.some((s) => !s?.isActive) && (
              <span
                className={
                  showInactive
                    ? "btn btn-sm btn-secondary"
                    : "btn btn-sm btn-outline-secondary"
                }
                onClick={() => setShowInactive(!showInactive)}
              >
                Inactive
              </span>
            )}
          </div>
          <Link className="btn btn-sm btn-primary ml-2" to="/sites/new">
            Add New
          </Link>
        </div>
      </div>

      <ShareParkTable
        data={data?.sites ?? { items: [], totalCount: 0 }}
        columns={columns as ColumnDef<Site>[]}
        pagination={pagination}
        setPagination={setPagination}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        loading={loading}
        isActiveKey="isActive"
      />
    </>
  );
};
