import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import { GetUsersQuery, UserFilterInput } from "../../gql/graphql";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import ShareParkTable, {
  usePaginationAndFilters,
} from "../../components/table/ShareParkTable";

export const USERS = gql`
  query GetUsers($where: UserFilterInput, $take: Int, $skip: Int) {
    users(where: $where, take: $take, skip: $skip) {
      totalCount
      items {
        id
        email
        firstName
        lastName
        mobile
        isActive #need to select values in where clause
      }
    }
  }
`;

type User = NonNullable<
  NonNullable<NonNullable<GetUsersQuery["users"]>["items"]>[number]
>;

const columnHelper = createColumnHelper<User>();

const columns = [
  columnHelper.accessor("email", {
    header: "Email",
    cell: (info) => {
      return (
        <Link to={"/users/" + info.row.original.id}>{info.renderValue()}</Link>
      );
    },
  }),
  columnHelper.accessor("firstName", {
    header: "First Name",
  }),
  columnHelper.accessor("lastName", {
    header: "Last Name",
  }),
  columnHelper.accessor("mobile", {
    header: "Mobile",
  }),
];

export const Users = () => {
  const { pagination, setPagination, columnFilters, setColumnFilters } =
    usePaginationAndFilters();
  const [showInactive, setShowInactive] = useState(false);

  const where = React.useMemo(() => {
    const where: UserFilterInput = {};
    if (!showInactive) {
      where.isActive = { eq: true };
    }
    for (const filter of columnFilters) {
      switch (filter.id) {
        case "email":
        case "firstName":
        case "lastName":
        case "mobile":
          if (filter.value != null) {
            where[filter.id] = { contains: String(filter.value) };
          }
          break;
      }
    }
    return where;
  }, [showInactive, columnFilters]);
  const { data, loading } = useQuery<GetUsersQuery>(USERS, {
    variables: {
      take: pagination.pageSize,
      skip: pagination.pageSize * pagination.pageIndex,
      where,
    },
    fetchPolicy: "network-only",
  });

  // const impersonateUser = (e, id) => {
  //   impersonate(id);
  //   e.preventDefault();
  // };

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Users</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2">
            {
              <span
                className={
                  showInactive
                    ? "btn btn-sm btn-secondary"
                    : "btn btn-sm btn-outline-secondary"
                }
                onClick={() => setShowInactive(!showInactive)}
              >
                Inactive
              </span>
            }
          </div>
          <Link className="btn btn-sm btn-primary ml-2" to="/users/new">
            Add New
          </Link>
        </div>
      </div>

      <ShareParkTable
        data={data?.users ?? { items: [], totalCount: 0 }}
        columns={columns as ColumnDef<User>[]}
        pagination={pagination}
        setPagination={setPagination}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        loading={loading}
        isActiveKey="isActive"
      />
    </div>
  );
};
