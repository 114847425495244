import React, { FC } from "react";

const Loading: FC<{ loading: boolean }> = ({ loading }) => {
  return (
    <>
      {loading ? (
        <div className="loading">
          <div className="loading-spinner" />
        </div>
      ) : null}
    </>
  );
};

export default Loading;
